import { AxiosResponse } from "axios";
import { apiFetch } from "../utils/api";

export const apiValidateJoinFirstStepForm = async (
  data: any
): Promise<AxiosResponse<any>> => {
  const source = apiFetch("/validate/join/first-step");
  try {
    const response = await source.create(data);
    return response;
  } catch (err: any) {
    return err.response;
  }
};

export const apiValidateJoinSecondStepForm = async (
  data: any
): Promise<AxiosResponse<any>> => {
  const source = apiFetch("/validate/join/second-step");
  try {
    const response = await source.create(data);
    return response;
  } catch (err: any) {
    return err.response;
  }
};
