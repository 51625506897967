export const DASHBOARD_ROOT_URL = "p";
export const PUBLIC_DISHES_ROOT_URL = "k";
export const PUBLIC_KITCHEN_ROOT_URL = "d";

export const ABOUT = "/about";
export const PRICE = "/price";
export const FAQ = "/faq";
export const TERMS_OF_SERVICE = "/terms-of-use";
export const PRIVACY_POLICY = "/privacy-policy";
export const CHECKOUT = "/checkout";
export const PREVIOUS_ORDERS= "/previous-orders";

const DISHES = "dishes";
const KITCHENS = "kitchens";
const PROFILES = "profiles";
const ACCOUNTS = "accounts";
const COMMANDS = "commands";

export const DASHBOARD_ACCOUNTS = `/${DASHBOARD_ROOT_URL}/${ACCOUNTS}`;
export const DASHBOARD_KITCHENS = `/${DASHBOARD_ROOT_URL}/${KITCHENS}`;
export const DASHBOARD_PROFILES = `/${DASHBOARD_ROOT_URL}/${PROFILES}`;
export const DASHBOARD_KITCHENS_DISHES = `/${DASHBOARD_ROOT_URL}/${KITCHENS}/${DISHES}`;
export const DASHBOARD_KITCHENS_COMMANDS_AT_KITCHEN = `/${DASHBOARD_ROOT_URL}/${KITCHENS}/${COMMANDS}/at-kitchen`;
export const DASHBOARD_KITCHENS_COMMANDS_COOKED = `/${DASHBOARD_ROOT_URL}/${KITCHENS}/${COMMANDS}/cooked`;
export const DASHBOARD_KITCHENS_COMMANDS_SERVED = `/${DASHBOARD_ROOT_URL}/${KITCHENS}/${COMMANDS}/served`;
export const DASHBOARD_KITCHENS_SCHEDULES = `/${DASHBOARD_ROOT_URL}/${KITCHENS}/schedules`;

export const getKitchenUrl = (kitchenId: string) =>
  `/${PUBLIC_DISHES_ROOT_URL}/${kitchenId}`;
export const getKitchenDetailsUrl = (kitchenId: string) =>
  `/${PUBLIC_KITCHEN_ROOT_URL}/${kitchenId}`;
