import { UserFull, UserExtended, USER_ACTION } from "../../types/user.d";

export const setUserFull = (userFull: UserFull) => {
  return {
    type: USER_ACTION.SET_USER_FULL,
    payload: userFull,
  };
};

export const updateUserExtended = (userExtended: UserExtended) => {
  return {
    type: USER_ACTION.UPDATE_USER_EXTENDED,
    payload: userExtended,
  };
};
