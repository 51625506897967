import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { sideMenu as esSideMenu } from "./constants/lang/es/sideMenu";
import { sideMenu as enSideMenu } from "./constants/lang/en/sideMenu";
import { viewHome as esViewHome } from "./constants/lang/es/viewHome";
import { viewHome as enViewHome } from "./constants/lang/en/viewHome";
import { footer as esFooter } from "./constants/lang/es/footer";
import { footer as enFooter } from "./constants/lang/en/footer";
import { modals as esModals } from "./constants/lang/es/modals";
import { modals as enModals } from "./constants/lang/en/modals";
import { esForms } from "./constants/lang/es/forms";
import { enForms } from "./constants/lang/en/forms";
import { esViewSeller } from "./constants/lang/es/viewSeller";
import { enViewSeller } from "./constants/lang/en/viewSeller";
import { esMessages } from "./constants/lang/es/messages";
import { enMessages } from "./constants/lang/en/messages";
import { esViewKitchen } from "./constants/lang/es/viewKitchen";
import { enViewKitchen } from "./constants/lang/en/viewKitchen";
import { esViewSchedules } from "./constants/lang/es/viewSchedules";
import { enViewSchedules } from "./constants/lang/en/viewSchedules";
import { esViewDishes } from "./constants/lang/es/viewDishes";
import { enViewDishes } from "./constants/lang/en/viewDishes";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "es",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          footer: { ...enFooter },
          forms: { ...enForms },
          sideMenu: { ...enSideMenu },
          view: {
            home: { ...enViewHome },
            dishes: { ...enViewDishes },
            seller: { ...enViewSeller },
            restaurant: { ...enViewKitchen },
            schedules: { ...enViewSchedules },
          },
          messages: { ...enMessages },
          modals: { ...enModals },
        },
      },
      es: {
        translation: {
          footer: { ...esFooter },
          forms: { ...esForms },
          sideMenu: { ...esSideMenu },
          view: {
            home: { ...esViewHome },
            dishes: { ...esViewDishes },
            seller: { ...esViewSeller },
            restaurant: { ...esViewKitchen },
            schedules: { ...esViewSchedules },
          },
          messages: { ...esMessages },
          modals: { ...esModals },
        },
      },
    },
  });

export default i18n;
