import { AnyAction } from "redux";

import { CATEGORY_ACTION, Category } from "../../types/category.d";
import { pushMultipleElementsToState } from "../utils";

const initialState = [] as Category[];

const setCategories = (state: typeof initialState, action: AnyAction) => {
  return pushMultipleElementsToState(state, action.payload);
};

const Reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case CATEGORY_ACTION.SET_CATEGORIES:
      return setCategories(state, action);
    default:
      return state;
  }
};

export default Reducer;
