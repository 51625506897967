import React, { FC, lazy } from "react";
import { Route } from "react-router-dom";

import { RoutesPublic } from "./Public";
import Loader from "./Loader";
import Alert from "../components/Alert";
import {
  DASHBOARD_ACCOUNTS,
  DASHBOARD_KITCHENS,
  DASHBOARD_KITCHENS_DISHES,
  DASHBOARD_KITCHENS_SCHEDULES,
  DASHBOARD_KITCHENS_COMMANDS_AT_KITCHEN,
  DASHBOARD_KITCHENS_COMMANDS_COOKED,
  DASHBOARD_KITCHENS_COMMANDS_SERVED,
  DASHBOARD_PROFILES,
} from "../constants/routes";

const AccountIndex = lazy(() => {
  return import("../views/seller/accounts/Index");
});

const ProfileIndex = lazy(() => {
  return import("../views/seller/profiles/Index");
});

//const ProfileSellerBankAccount = lazy(() => {
//  return import("../views/seller/profile/BankAccounts");
//});

//const ProfileSellerAddresses = lazy(() => {
//  return import("../views/seller/profile/Addresses");
//});

const CommmandsAtKitchen = lazy(() => {
  return import("../views/seller/commands/AtKitchen");
});

const CommmandsCooked = lazy(() => {
  return import("../views/seller/commands/Cooked");
});

const CommmandsServed = lazy(() => {
  return import("../views/seller/commands/Served");
});

const DishesIndex = lazy(() => {
  return import("../views/seller/dishes/Index");
});

const KitchenIndex = lazy(() => {
  return import("../views/seller/kitchens/Index");
});

// const BusinessAddressesSeller = lazy(() => {
//   return import("../views/seller/business/Addresses");
// });

const KitchenSchedules = lazy(() => {
  return import("../views/seller/kitchens/Schedules");
});

//const SalesSeller = lazy(() => {
//  return import("../views/seller/sales/Index");
//});

// MODALS
// const SearchAddressModal = lazy(() => {
//   return import("../components/Modals/SearchAddress");
// });

const DishPublicModal = lazy(() => {
  return import("../components/Modals/DishPublic");
});

// const OrderDetailsModal = lazy(() => {
//   return import("../components/Modals/OrderDetails");
// });
//
// const OrderHelpModal = lazy(() => {
//   return import("../components/Modals/OrderHelp");
// });
//
// const PositionMapModal = lazy(() => {
//   return import("../components/Modals/PositionMap");
// });
//
// const MultipleAddressesModal = lazy(() => {
//   return import("../components/Modals/MultipleAddresses");
// });

// const CartButton = lazy(() => {
//   return import("../components/CartButton");
// });

const RoutesSeller: FC = () => {
  // const _session = useSessionSelector();
  // const userType = _session?.permissions || getUserType();

  return (
    <Loader
      notRoute={
        <>
          {/*
            <CartButton />
            <MenuSide />
            <MenuProfileSide userType={userType as any} />

            <SearchAddressModal />
            <PositionMapModal />
            <MultipleAddressesModal />

            <OrderDetailsModal />
            <OrderHelpModal />
          */}
          <DishPublicModal modalId="dish-public-modal" />

          <Alert />
        </>
      }
    >
      <Route path={DASHBOARD_ACCOUNTS} exact component={AccountIndex} />

      {/*<Route path="/dashboard/sales" exact component={SalesSeller} />*/}

      {/*<Route
        path="/dashboard/business/addresses"
        exact
        component={BusinessAddressesSeller}
        />*/}

      <Route
        path={DASHBOARD_KITCHENS_SCHEDULES}
        exact
        component={KitchenSchedules}
      />

      <Route path={DASHBOARD_KITCHENS} exact component={KitchenIndex} />

      <Route path={DASHBOARD_KITCHENS_DISHES} exact component={DishesIndex} />

      <Route
        path={DASHBOARD_KITCHENS_COMMANDS_AT_KITCHEN}
        exact
        component={CommmandsAtKitchen}
      />

      <Route
        path={DASHBOARD_KITCHENS_COMMANDS_COOKED}
        exact
        component={CommmandsCooked}
      />

      <Route
        path={DASHBOARD_KITCHENS_COMMANDS_SERVED}
        exact
        component={CommmandsServed}
      />

      {/*<Route path="/dashboard/commands" exact component={CommandsSeller} />*/}

      {/*
      <Route
        path="/dashboard/profile/bank-accounts"
        exact
        component={ProfileSellerBankAccount}
      />

      <Route
        path="/dashboard/profile/addresses"
        exact
        component={ProfileSellerAddresses}
      />
      */}

      <Route path={DASHBOARD_PROFILES} exact component={ProfileIndex} />

      <RoutesPublic />
    </Loader>
  );
};

export default RoutesSeller;
