import React, { FC } from "react";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import thunk, { ThunkAction } from "redux-thunk";
import { combineReducers, Action } from "redux";

import _pageReducer from "./_page/reducer";
import accountReducer from "./accounts/reducer";
import categoriesReducer from "./categories/reducer";
import dishesReducer from "./dishes/reducer";
import kitchensReducer from "./kitchens/reducer";
import newslettersReducer from "./newsletters/reducer";
import commandReducer from "./commands/reducer";
import userReducer from "./users/reducers";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  _page: _pageReducer,
  accounts: accountReducer,
  categories: categoriesReducer,
  dishes: dishesReducer,
  kitchens: kitchensReducer,
  newsletters: newslettersReducer,
  users: userReducer,
  commands: commandReducer,
});

const storeCreated = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

const Store: FC<React.ReactNode> = ({ children }) => {
  return <Provider store={storeCreated}>{children}</Provider>;
};

export type RootStateType = ReturnType<typeof rootReducer>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootStateType,
  unknown,
  Action<string>
>;

export default Store;
