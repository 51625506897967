import { AnyAction } from "redux";

import { COMMAND_ACTION, Command } from "../../types/command.d";

const initialState = [] as Command[];

const setCommands = (action: AnyAction) => {
  return [...action.payload];
};

const Reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case COMMAND_ACTION.SET_COMMANDS:
      return setCommands(action);
    default:
      return state;
  }
};

export default Reducer;
