export const footer = {
  sections: {
    subscribe: {
      title: "Do you want to hear about updates and special offers?",
      button: "Subscribe",
    },
    links: {
      company: {
        title: "Company",
        whoAreWe: "Who are we?",
        pricing: "Pricing",
        faq: "FAQ",
      },
      profile: {
        title: "Profile",
        myProducts: "My products",
        myProfile: "My profile",
      },
      termsAndPolicies: {
        terms: "Terms of service",
        privacy: "Policy privacy",
      },
    },
    copyRight: "All rights reserved",
  },
};
