import { MEX_FLAG, US_FLAG } from "../../emojis";

export const enForms = {
  inputs: {
    email: "Email",
    personalEmail: "Personal email",
    personalPhone: "Personal phone",
    password: "Password",
    role: "Role",
    firstName: "First Name",
    lastName: "Last Name",
    businessName: "Name of your business",
    businessPhone: "Phone of your business",
    businessPhoneCode: "Phone code",
    businessEmail: "Email of your business",
    businessDescription:
      "What motivates you be a chef? Why do you chef? What is your best dish?",
    dishDescription: "Describe the dish",
    dishCategory: "Category",
    dishCategorySelect: "Select a category",
    dishCost: "Price",
    dishName: "Name of dish",
    dishStatus: "Is it available?",
    dishEnabled: "Enabled",
    dishDisabled: "Hidden",
    selectImages: "Select images (MAX: {{max}})",
    imagesSize: "Recomended size {{size}}",
    address: "Address",
    gender: "Gender",
    noneGender: "I'd rather not say",
    femaleGender: "Female",
    maleGender: "Male",
    accountType: "Type of account",
    accountLanguage: "Account's language",
    currency: "Type of currency",
    numberOfTables: "Number of tables",
    anyInstructions: "Any instructions?",
  },
  placeholders: {
    role: "Only menus can be registered",
    businessName: 'Restaurant "Doña Mary"',
    businessEmail: "my_kitchen@hadamenu.com",
    businessDescription: `Heir to a family tradition linked to good cooking for several generations, "Doña Chely's" cooking is a legacy that talent transformed into a vocation, and a gastronomy legend in our town. Being part of "Hada Menú" is where I can do what I love and spend quality time with the family.`,
    dishName: "Ex. Chicken Pot Pie",
    dishDescription:
      "Roast chicken, baby carrots, spring peas topped with grandma’s flakey pie crust",
    address: "Ex. 201 E Randolph St, Chicago, IL 60602",
  },
  helperText: {
    password:
      "8 characters including at least one uppercase, one lowercase, one number, and one symbol",
    personalEmail:
      "To change your email, please contact us. We'll do it for you. Our email is ayuda@hadamenu.com",
    currency:
      "Customers will see this as their payment currency when purchasing their food. Important, the target account must match this setting.",
    accountLanguage:
      "This information helps us understand what kind of texts to show you and your customers.",
    maxCharacters: "{{current}}/{{max}} characthers",
  },
  selectOption: {
    currency: {
      unspecified: "Unspecified",
      mxn: `${MEX_FLAG} Mexican peso`,
      usd: `${US_FLAG} US dollar`,
    },
    language: {
      spanish: "Spanish",
      english: "English",
    },
  },
  buttons: {
    enter: "Continue",
    next: "Next",
    back: "Back",
    finishRegistration: "Finish Registration",
    save: "Save",
    delete: "Delete",
  },
  errors: {
    checkInputs: "Check fields are correctly filled, please",
    checkCredentials: "Something went wrong, check credentials",
    serverError: "Something went wrong, please try again later",
    userNotCreated: "Try later, we couldn't create your user",
    pickAnAddress: "Pick an address",
    noAddressMatches: "No matches. try another direction",
  },
};
