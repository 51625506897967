// Install with 'npm i js-cookie'. A library that helps you manage cookies
// (or just build your own).
import Cookies from "js-cookie";
import axios from "axios";

const DOMAIN_URL = process.env.REACT_APP_API_URL;

// Create axios instance with base url and credentials support
export const axiosInstance = axios.create({
  baseURL: DOMAIN_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

// Request interceptor. Runs before your request reaches the server
export const onRequest = (config: any) => {
  // If http method is `post | put | delete` and XSRF-TOKEN cookie is
  // not present, call '/sanctum/csrf-cookie' to set CSRF token, then
  // proceed with the initial response
  if (
    (config.method === "post" ||
      config.method === "put" ||
      config.method === "delete") &&
    !Cookies.get("XSRF-TOKEN")
  ) {
    return setCSRFToken().then(() => config);
  }

  config.headers["locale"] = localStorage.getItem("i18nextLng");
  return config;
};

// A function that calls '/api/csrf-cookie' to set the CSRF cookies. The
// default is 'sanctum/csrf-cookie' but you can configure it to be anything.
const setCSRFToken = () => {
  return axiosInstance.get("/sanctum/csrf-cookie"); // resolves to '/api/csrf-cookie'.
};

// attach your interceptor
axiosInstance.interceptors.request.use(onRequest);

export default axiosInstance;
