import { useDispatch, useSelector } from "react-redux";
import { set } from "lodash";

import { Kitchen } from "../../types/kitchen.d";
import { AppThunk, RootStateType } from "../Main";
import { PAGE_REDUCERS, setKitchensLoading } from "../_page/actions";
import { updateKitchen } from "./actions";
import { apiPutKitchen, apiGetKitchen } from "../../api/kitchens";
import { IPaginationResponse } from "../../api/utils";

export const useKitchenSelector = (): Kitchen[] => {
  return useSelector((state: RootStateType) => state.kitchens) as Kitchen[];
};

export const useKitchenPageSelector = (): IPaginationResponse & {
  isLoading: boolean;
} => {
  return useSelector(
    (state: RootStateType) => state._page[PAGE_REDUCERS.kitchens]
  );
};

export const runApiPutKitchen =
  (partialKitchen: Partial<Kitchen & { phone_number: string }>): AppThunk =>
  async (dispatch) => {
    await dispatch(setKitchensLoading(true));
    try {
      const updatedKitchen = { ...partialKitchen } as any;
      set(
        updatedKitchen,
        "address.lat",
        updatedKitchen?.address?.lat
          ? updatedKitchen.address.lat.toString()
          : ""
      );
      set(
        updatedKitchen,
        "address.long",
        updatedKitchen?.address?.long
          ? updatedKitchen.address.long.toString()
          : ""
      );
      if (updatedKitchen?.address?.address) {
        set(
          updatedKitchen,
          "address.full_address",
          updatedKitchen.address.address
        );
        delete updatedKitchen.address.address;
      }
      const res = await apiPutKitchen(partialKitchen);
      await dispatch(setKitchensLoading(false));
      if (res.status === 400) {
        return { data: res.data, status: res.status };
      } else if (res.status >= 300) {
        throw new Error(JSON.stringify(res));
      }
      await dispatch(updateKitchen({ ...res.data }));
      return res.data;
    } catch (err) {
      await dispatch(setKitchensLoading(false));
      throw err;
    }
  };

export const useApiPutKitchen = () => {
  const dispatch = useDispatch();
  return (partialKitchen: Partial<Kitchen & { phone_number: string }>) =>
    dispatch(runApiPutKitchen(partialKitchen));
};

export const runApiGetKitchen =
  (kitchenId: string): AppThunk =>
  async (dispatch) => {
    await dispatch(setKitchensLoading(true));
    try {
      const res = await apiGetKitchen(kitchenId);
      await dispatch(setKitchensLoading(false));
      if (res.status >= 300) {
        throw new Error(JSON.stringify(res));
      }
      return res.data;
    } catch (err) {
      await dispatch(setKitchensLoading(false));
      throw err;
    }
  };

export const useApiGetKitchen = () => {
  const dispatch = useDispatch();
  return (kitchenId: string) => dispatch(runApiGetKitchen(kitchenId));
};
