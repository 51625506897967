import { InitialInfo } from "../../types/user";
import { IPaginationResponse } from "../../api/utils";

export enum PAGE_ACTION {
  SET_SESSION = "SET_SESSION",

  SET_ACCOUNTS_LOADING = "SET_ACCOUNTS_LOADING",
  SET_CATEGORIES_LOADING = "SET_CATEGORIES_LOADING",
  SET_DISHES_LOADING = "SET_DISHES_LOADING",
  SET_KITCHENS_LOADING = "SET_KITCHENS_LOADING",
  SET_NEWSLETTERS_LOADING = "SET_NEWSLETTERS_LOADING",
  SET_USERS_LOADING = "SET_USERS_LOADING",
  SET_FORMS_LOADING = "SET_FORMS_LOADING",

  SET_CATEGORIES_PAGINATION = "SET_CATEGORIES_PAGINATION",
  SET_DISHES_PAGINATION = "SET_DISHES_PAGINATION",

  SET_COMMANDS_LOADING = "SET_COMMANDS_LOADING",
}

export enum PAGE_REDUCERS {
  accounts = "accounts",
  categories = "categories",
  dishes = "dishes",
  kitchens = "kitchens",
  newsletters = "newsletters",
  users = "users",
  commands = "commands",
  forms = "forms",
  session = "session",
}

export const setSession = (data: InitialInfo) => {
  return {
    type: PAGE_ACTION.SET_SESSION,
    payload: data,
  };
};

export const setAccountsLoading = (isLoading = false) => {
  return {
    type: PAGE_ACTION.SET_ACCOUNTS_LOADING,
    payload: {
      tableName: PAGE_REDUCERS.accounts,
      isLoading,
    },
  };
};

export const setCategoriesLoading = (isLoading = false) => {
  return {
    type: PAGE_ACTION.SET_CATEGORIES_LOADING,
    payload: {
      tableName: PAGE_REDUCERS.categories,
      isLoading,
    },
  };
};

export const setDishesLoading = (isLoading = false) => {
  return {
    type: PAGE_ACTION.SET_DISHES_LOADING,
    payload: {
      tableName: PAGE_REDUCERS.dishes,
      isLoading,
    },
  };
};

export const setKitchensLoading = (isLoading = false) => {
  return {
    type: PAGE_ACTION.SET_KITCHENS_LOADING,
    payload: {
      tableName: PAGE_REDUCERS.kitchens,
      isLoading,
    },
  };
};

export const setNewslettersLoading = (isLoading = false) => {
  return {
    type: PAGE_ACTION.SET_NEWSLETTERS_LOADING,
    payload: {
      tableName: PAGE_REDUCERS.newsletters,
      isLoading,
    },
  };
};

export const setUsersLoading = (isLoading = false) => {
  return {
    type: PAGE_ACTION.SET_USERS_LOADING,
    payload: {
      tableName: PAGE_REDUCERS.users,
      isLoading,
    },
  };
};

export const setFormsLoading = (formName: string, isLoading = false) => {
  return {
    type: PAGE_ACTION.SET_FORMS_LOADING,
    payload: {
      formName,
      isLoading,
    },
  };
};

export const setCategoriesPagination = <T>(
  res: IPaginationResponse & { data: T }
) => {
  return {
    type: PAGE_ACTION.SET_CATEGORIES_PAGINATION,
    payload: {
      tableName: PAGE_REDUCERS.categories,
      meta: res.meta,
      links: res.links,
    },
  };
};

export const setDishesPagination = <T>(
  res: IPaginationResponse & { data: T }
) => {
  return {
    type: PAGE_ACTION.SET_DISHES_PAGINATION,
    payload: {
      tableName: PAGE_REDUCERS.dishes,
      meta: res.meta,
      links: res.links,
    },
  };
};

export const setCommandsLoading = (isLoading = false) => {
  return {
    type: PAGE_ACTION.SET_COMMANDS_LOADING,
    payload: {
      tableName: PAGE_REDUCERS.commands,
      isLoading,
    },
  };
};
