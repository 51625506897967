import { AnyAction } from "redux";

import { KITCHEN_ACTION, Kitchen } from "../../types/kitchen.d";
import { pushNewElementToState, updateElementInState } from "../utils";

const initialState = [] as Kitchen[];

const setKitchen = (state: typeof initialState, action: AnyAction) => {
  return pushNewElementToState(state, action.payload);
};

const updateKitchen = (state: typeof initialState, action: AnyAction) => {
  return updateElementInState(state, action.payload);
};

const Reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case KITCHEN_ACTION.SET_KITCHEN:
      return setKitchen(state, action);
    case KITCHEN_ACTION.UPDATE_KITCHEN:
      return updateKitchen(state, action);
    default:
      return state;
  }
};

export default Reducer;
