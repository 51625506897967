export const sideMenu = {
  public: {
    buttons: {
      home: "Principal",
      signup: "Regístrate",
      signin: "Iniciar Sesión",
      previousOrders: "Ordenes anteriores",
    },
    welcomeMessage: "Bienvenid@",
  },
  seller: {
    buttons: {
      myProducts: "Platillos",
      account: "Configurar cuenta",
      business: "Configurar cocina",
      workingHours: "Horario de trabajo",
      profile: "Configurar perfil",
      signOff: "Cerrar sesión",
      commands: "Comandas",
      atKitchenCommands: "En cocina",
      cookedCommands: "Listo para servir",
      servedCommands: "Servidos",
    },
    welcomeMessage: "<span>Bienvenido 👋,</span><br /><span>{{name}}</span>",
  },
  version: "Versión",
};
