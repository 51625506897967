export const pushNewElementToState = <T>(state: T[], payload: T): T[] => {
  let elementExist = false;
  for (const element of state) {
    if ((element as any).id === (payload as any).id) {
      elementExist = true;
      break;
    }
  }
  if (elementExist) {
    return [...state];
  }
  return [payload, ...state];
};

export const updateElementInState = <T>(state: T[], payload: T): T[] => {
  const updatedState = [];
  for (const element of state) {
    if ((element as any).id === (payload as any).id) {
      updatedState.push(payload);
    } else {
      updatedState.push(element);
    }
  }
  return updatedState;
};

export const pushMultipleElementsToState = <T>(
  state: T[],
  payload: T[]
): T[] => {
  const elementIdsAlreadyStored = state.map((e: any) => e.id);
  const listOfNewElements = [];
  for (const elementToPush of payload) {
    if (!elementIdsAlreadyStored.includes((elementToPush as any).id)) {
      listOfNewElements.push(elementToPush);
    }
  }
  return [...state, ...listOfNewElements];
};
