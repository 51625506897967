import { apiFetch } from "../utils/api";
import { AxiosResponse } from "axios";
import { InitialInfo } from "../types/user";
import { getUserToken } from "./utils";

export const apiPostLogin = async (data: {
  email: string;
  password: string;
}): Promise<AxiosResponse<InitialInfo>> => {
  const source = apiFetch("/login");
  try {
    const response = await source.create(data);
    return response;
  } catch (err: any) {
    return err.response;
  }
};

export const apiPostLogout = async (): Promise<AxiosResponse<void>> => {
  const source = apiFetch("/logout");
  try {
    const authToken = getUserToken();
    const response = await source.create(
      {},
      {
        Authorization: authToken,
      }
    );
    return response;
  } catch (err: any) {
    return err.response;
  }
};

export const apiPostInitialData = async (): Promise<
  AxiosResponse<InitialInfo>
> => {
  const source = apiFetch("/load-initial-data");
  try {
    const authToken = getUserToken();
    const response = await source.create(
      {},
      {
        Authorization: authToken,
      }
    );
    return response;
  } catch (err: any) {
    return err.response;
  }
};
