export const enViewSchedules = {
  schedules: "Business schedules",
  day: {
    monday: "monday",
    tuesday: "tuesday",
    wednesday: "wednesday",
    thursday: "thursday",
    friday: "friday",
    saturday: "saturday",
    sunday: "sunday",
  },
  isItOpen: "Is it open?",
  startsAt: "Starts at",
  endsAt: "Ends at",
  callForMoreInformation: "Call for more information",
  closed: "Closed",
};
