const INSTRUCTIONS = "instructions";

export const enMessages = {
  addToCart: "Add to cart",
  addingItemToCart: "Adding...",
  addedItemToCart: "Added to your cart!",
  useQRCode: "Please use the QR code to start ordering",
  updating: "Updating...",
  updated: "Updated!",
  creating: "Creating...",
  created: "Created!",
  deleting: "Deleting...",
  deleted: "¡Deleted!",
  delete: "Delete",
  cart: "Cart",
  confirmation: "Confirmation",
  confirmOrder: "Confirm Order",
  sendOrder: "Send order",
  orderMore: "Order More",
  emptyCart: "Empty cart",
  goBackToKitchen: "Go back to kitchen",
  cost: "Cost",
  back: "Back",
  table: "Table",
  showInstructions: `Show ${INSTRUCTIONS}`,
  hideInstructions: `Hide ${INSTRUCTIONS}`,
  contact: "Contact",
  phone: "Phone",
  thanks: "Thank you",
  inAMomentYoullReceiveYourOrder: "In a moment you will receive your order",
  redirecting: "Redirecting",
  noRelevantInfo: "No relevant information",
  yes: "yes",
  no: "no",
  linkCopied: "Link copied",
  new: "New",
  qrHeader:
    "This QR code redirects to your public menu. You can give it to your clients",
  qrHelp1:
    "If you need us to generate the QR code for you, send us an email to",
  qrHelp2:
    "and we will respond to the email with an image of the code attached to it",
  moreKitchenInfo: "More information about the kitchen",
  locationWithGoogle: "Location w/Google maps",
  noAvailableCommand: "No available commands",
  messageNoCommands:
    "If you think this is an issue, please send us an email to",
  quantityAbbr: "Qty",
  dishName: "Dish Name",
  instructions: INSTRUCTIONS,
  prepping: "Preparing",
  readyToServe: "Ready to serve",
  served: "Served",
  paid: "Paid",
  unpaid: "Unpaid",
  cancelled: "Cancelled",
  status: "Status",
  cooked: "Cooked",
  notDoingThis: "Not doing this",
  notAttendingThis: "Not attending this",
  cancelOnBehalf: "Cancel on behalf",
  markAs: "Mark as",
};
