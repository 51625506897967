import React, { Suspense } from "react";
import { Switch } from "react-router-dom";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { AppProvider } from "../store/context/App";
import Navbar from "../layouts/Navbar";
import Footer from "../layouts/Footer";
// import SearchContainer from "../components/SearchContainer";
// import { RoutesLoaderInterface } from "../types/routes/main";
import Loader from "../components/UI/Loader";

const RoutesLoader: React.FunctionComponent<Record<string, any>> = ({
  children,
  notRoute,
}) => {
  const classes = useStyles();

  return (
    <AppProvider>
      <Navbar />
      <CssBaseline />
      {/*
      <SearchContainer />
      */}
      <Container maxWidth="lg" className={classes.root}>
        <Suspense fallback={<Loader />}>
          <Switch>{children}</Switch>
          {notRoute ? notRoute : null}
        </Suspense>
      </Container>
      <Footer />
    </AppProvider>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: "relative",
      minHeight: "calc(65vh - 64px)",
      marginTop: "22px",
      marginBottom: "30px",
    },
  })
);

export default RoutesLoader;
