export const viewHome = {
  sections: {
    mission: {
      label: "MISSION",
      description: "Empowering women of the world",
      text: `The female headship has increased in recent years for economic,
      social and psychological reasons. Where many women exercise their
      motherhood without a partner for any reason. Let's use the "Hada Menu"
      and let the children of these mothers have a better quality of life
      through policies aimed at reconciling work and heads of family.
      Incorporating them into the workplace where they will have time to care
      for babies after delivery and with flexible hours that allow them to take
      care of their family without affecting wages and rights.`,
    },
    categories: {
      label: "Food that exists in our catalog",
      name: {
        tacos: "Tacos",
        cafe_o_te: "Coffee or Tea",
        regional: "Mexican Regional",
        sandwiches: "Sandwiches",
        alcohol: "Alcohol",
        pasteleria: "Cakes",
        bebidas: "Beverages",
        ensaladas: "Salads",
        mexicana: "Mexican",
        pizzas: "Pizzas",
        mariscos: "Sea Food",
        panaderia: "Bakery",
        hamburguesas: "Hamburgers",
        sopas: "Soups",
        pastas: "Pastas",
        postres: "Desserts",
        otro: "Other",
        desayunos: "Breakfast",
        alitas: "Wings",
        entradas: "Entrees",
        para_compartir: "To share",
      },
    },
  },
};
