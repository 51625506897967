import { apiFetch } from "../utils/api";
import { getUserToken } from "./utils";
import { Kitchen } from "../types/kitchen.d";
import { AxiosResponse } from "axios";

export const apiPutKitchen = async (
  data: Partial<Kitchen & { phone_number: string }>
): Promise<AxiosResponse<Kitchen>> => {
  const source = apiFetch("/kitchens");
  try {
    const authToken = getUserToken();
    const response = await source.update(data, {
      Authorization: authToken,
    });
    return response;
  } catch (err: any) {
    return err.response;
  }
};

export const apiGetKitchen = async (
  kitchenId: string
): Promise<AxiosResponse<Kitchen>> => {
  const source = apiFetch(`/kitchens/${kitchenId}`);
  try {
    const response = await source.read();
    return response;
  } catch (err: any) {
    return err.response;
  }
};
