import { Phone } from "./phone";
import { Address } from "./address";
import { Image } from "./image";

export enum KITCHEN_ACTION {
  SET_KITCHEN = "SET_KITCHEN",
  UPDATE_KITCHEN = "UPDATE_KITCHEN",
}

interface Day {
  isOpen: boolean;
  starts: string;
  ends: string;
}

export interface Kitchen {
  id: string;
  mon: Day | null;
  tue: Day | null;
  wed: Day | null;
  thu: Day | null;
  fri: Day | null;
  sat: Day | null;
  sun: Day | null;
  email: string | null;
  phone?: Phone;
  address?: Address;
  images: Image[];
  name: string | null;
  description_es: string | null;
  description_en: string | null;
  created_at: number;
  updated_at: number;
}
