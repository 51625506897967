const INSTRUCTIONS = "instrucciones";

export const esMessages = {
  addToCart: "Agregar al carrito",
  addingItemToCart: "Agregando...",
  addedItemToCart: "Agregado a tu carrito!",
  useQRCode: "Por favor usa el código QR para empezar a ordernar",
  updating: "Actualizando...",
  updated: "¡Actualizado!",
  creating: "Creando...",
  created: "¡Creado!",
  deleting: "Eliminando...",
  deleted: "¡Eliminado!",
  delete: "Eliminar",
  cart: "Carrito",
  confirmation: "Confirmación",
  confirmOrder: "Confirmar orden",
  sendOrder: "Enviar orden",
  orderMore: "Ordenar más",
  emptyCart: "Carrito vacío ",
  goBackToKitchen: "Regresar a cocina",
  cost: "Costo",
  back: "Regresar",
  table: "Mesa",
  showInstructions: `Mostrar ${INSTRUCTIONS}`,
  hideInstructions: `Ocultar ${INSTRUCTIONS}`,
  contact: "Contacto",
  phone: "Teléfono",
  thanks: "Gracias",
  inAMomentYoullReceiveYourOrder: "En un momento recibirás tu orden",
  redirecting: "Redirigiendo",
  noRelevantInfo: "Sin información relevante",
  yes: "yes",
  no: "no",
  linkCopied: "Enlace copiado",
  new: "Nuevo",
  qrHeader:
    "Este código QR redirige a tu menú público. Puedes darlo a tus clientes.",
  qrHelp1:
    "Si necesitas que generemos el código QR por ti, envíanos un correo a",
  qrHelp2: "y nosotros responderemos al correo con imagen del código adjunta",
  moreKitchenInfo: "Más información acerca de la cocina",
  locationWithGoogle: "Ubicación con Google maps",
  noAvailableCommand: "No hay comandas disponibles",
  messageNoCommands:
    "Si cree que hay un problema, envíenos un correo electrónico a",
  quantityAbbr: "Cant",
  dishName: "Platillo",
  instructions: INSTRUCTIONS,
  prepping: "Preparando",
  readyToServe: "Listo para servir",
  served: "Servido",
  paid: "Pagado",
  unpaid: "Sin pagar",
  cancelled: "Cancelado",
  status: "Estado",
  cooked: "Cocinado",
  notDoingThis: "No trabajar esto",
  notAttendingThis: "No atender esto",
  cancelOnBehalf: "Cancelar en nombre",
  markAs: "Marcar como",
};
