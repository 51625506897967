import { Account, ACCOUNT_ACTION } from "../../types/account.d";

export const setAccount = (account: Account) => {
  return {
    type: ACCOUNT_ACTION.SET_ACCOUNT,
    payload: account,
  };
};

export const updateAccount = (account: Partial<Account>) => {
  return {
    type: ACCOUNT_ACTION.UPDATE_ACCOUNT,
    payload: account,
  };
};
