import React, { lazy } from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import Loader from "./Loader";
import Alert from "../components/Alert";
import ModalInviteRegistration from "../components/Modals/InviteRegistration";
import { PREVIOUS_ORDERS } from "../constants/routes";

const Home = lazy(() => {
  return import("../views/public/Home");
});

const Dishes = lazy(() => {
  return import("../views/public/Dishes");
});

const SellerDetails = lazy(() => {
  return import("../views/public/SellerDetails");
});

const Checkout = lazy(() => {
  return import("../views/public/Checkout");
});

const PreviousOrders = lazy(() => {
  return import("../views/public/PreviousOrders");
});

const About = lazy(() => {
  return import("../views/public/About");
});

const Price = lazy(() => {
  return import("../views/public/Price");
});

const FAQ = lazy(() => {
  return import("../views/public/FAQ");
});

const PrivacyPolicy = lazy(() => {
  return import("../views/public/PrivacyPolicy");
});

const TermsOfUse = lazy(() => {
  return import("../views/public/TermsOfUse");
});

// MODALS
// const SearchAddressModal = lazy(() => {
//   return import("../components/Modals/SearchAddress");
// });

const LoginModal = lazy(() => {
  return import("../components/Modals/Login");
});

const JoinModal = lazy(() => {
  return import("../components/Modals/Join");
});

// const ForgotPasswordModal = lazy(() => {
//   return import("../components/Modals/ForgotPassword");
// });
//
// const ResetPasswordModal = lazy(() => {
//   return import("../components/Modals/ResetPassword");
// });

const DishPublicModal = lazy(() => {
  return import("../components/Modals/DishPublic");
});

// const PositionMapModal = lazy(() => {
//   return import("../components/Modals/PositionMap");
// });
//
// const MultipleAddressesModal = lazy(() => {
//   return import("../components/Modals/MultipleAddresses");
// });

// const CartButton = lazy(() => {
//   return import("../components/CartButton");
// });

export const RoutesPublic: React.FunctionComponent = () => {
  return (
    <Switch>
      <Route exact path="/reset-password/:token" component={Home} />

      <Route exact path="/checkout" component={Checkout} />

      <Route exact path={PREVIOUS_ORDERS} component={PreviousOrders} />

      <Route exact path="/about" component={About} />

      <Route exact path="/price" component={Price} />

      <Route exact path="/faq" component={FAQ} />

      <Route exact path="/privacy-policy" component={PrivacyPolicy} />

      <Route exact path="/terms-of-use" component={TermsOfUse} />

      <Route exact path="/d/:kitchenId" component={SellerDetails} />

      <Route exact path="/k/:kitchenId" component={Dishes} />

      <Route exact path="/" component={Home} />

      <Route>
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

const PublicRouter: React.FunctionComponent = () => {
  return (
    <Loader
      notRoute={
        <>
          {/*
            <CartButton />
            <SearchAddressModal />
            <ForgotPasswordModal />
            <ResetPasswordModal />
            <PositionMapModal />
            <MultipleAddressesModal />
          */}

          <Alert />

          <LoginModal />
          <JoinModal />
          <DishPublicModal modalId="dish-public-modal" />
          <ModalInviteRegistration modalId="invite-registration-modal" />
        </>
      }
    >
      <RoutesPublic />
    </Loader>
  );
};

export default PublicRouter;
