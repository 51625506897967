import React from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ContainerSideMenu from "./Container";
import { UserFull } from "../../types/user";
import {
  DASHBOARD_ACCOUNTS,
  DASHBOARD_KITCHENS,
  DASHBOARD_KITCHENS_SCHEDULES,
  DASHBOARD_KITCHENS_DISHES,
  DASHBOARD_KITCHENS_COMMANDS_AT_KITCHEN,
  DASHBOARD_KITCHENS_COMMANDS_COOKED,
  DASHBOARD_KITCHENS_COMMANDS_SERVED,
  DASHBOARD_PROFILES,
  getKitchenDetailsUrl,
  getKitchenUrl,
} from "../../constants/routes";
import { useApiPostLogout } from "../../store/_page/utils";
import { scrollTop } from "../../utils/scroll";
import { useKitchenSelector } from "../../store/kitchens/utils";

type SetOpen = (state: ((prevState: boolean) => boolean) | boolean) => void;

const closeMenu = (setOpen: SetOpen) => {
  setOpen(false);
  scrollTop();
  document.documentElement.classList.remove("is-clipped");
};

export const SellerSideMenu: React.FunctionComponent<{
  open: boolean;
  setOpen: SetOpen;
}> = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const logout = useApiPostLogout();
  const sellerRouter = {
    account: useRouteMatch(DASHBOARD_ACCOUNTS)?.isExact
      ? t("sideMenu.seller.buttons.account")
      : undefined,
    myProducts: useRouteMatch(DASHBOARD_KITCHENS_DISHES)?.isExact
      ? t("sideMenu.seller.buttons.myProducts")
      : undefined,
    business: useRouteMatch(DASHBOARD_KITCHENS)?.isExact
      ? t("sideMenu.seller.buttons.business")
      : undefined,
    workingHours: useRouteMatch(DASHBOARD_KITCHENS_SCHEDULES)?.isExact
      ? t("sideMenu.seller.buttons.workingHours")
      : undefined,
    profile: useRouteMatch(DASHBOARD_PROFILES)?.isExact
      ? t("sideMenu.seller.buttons.profile")
      : undefined,
    atKitchenCommands: useRouteMatch(DASHBOARD_KITCHENS_COMMANDS_AT_KITCHEN)
      ?.isExact
      ? t("sideMenu.seller.buttons.atKitchenCommands")
      : undefined,
    cookedCommands: useRouteMatch(DASHBOARD_KITCHENS_COMMANDS_COOKED)?.isExact
      ? t("sideMenu.seller.buttons.cookedCommands")
      : undefined,
    servedCommands: useRouteMatch(DASHBOARD_KITCHENS_COMMANDS_SERVED)?.isExact
      ? t("sideMenu.seller.buttons.servedCommands")
      : undefined,
  };

  return (
    <ContainerSideMenu
      open={open}
      onClose={() => setOpen((prevState) => !prevState)}
    >
      <aside className="menu p-3">
        <p className="menu-label">Menu</p>
        <ul className="menu-list">
          <li>
            <Link
              to={DASHBOARD_ACCOUNTS}
              onClick={() => closeMenu(setOpen)}
              className={sellerRouter.account ? "active-link" : ""}
            >
              {t("sideMenu.seller.buttons.account")}
            </Link>
          </li>
          <li>
            <Link
              to={DASHBOARD_PROFILES}
              onClick={() => closeMenu(setOpen)}
              className={sellerRouter.profile ? "active-link" : ""}
            >
              {t("sideMenu.seller.buttons.profile")}
            </Link>
          </li>
          <li>
            <Link
              to={DASHBOARD_KITCHENS}
              onClick={() => closeMenu(setOpen)}
              className={sellerRouter.business ? "active-link" : ""}
            >
              {t("sideMenu.seller.buttons.business")}
            </Link>
            <ul>
              <li>
                <Link
                  to={DASHBOARD_KITCHENS_DISHES}
                  onClick={() => closeMenu(setOpen)}
                  className={sellerRouter.myProducts ? "active-link" : ""}
                >
                  {t("sideMenu.seller.buttons.myProducts")}
                </Link>
              </li>
              <li>
                <Link
                  to={DASHBOARD_KITCHENS_SCHEDULES}
                  onClick={() => closeMenu(setOpen)}
                  className={sellerRouter.workingHours ? "active-link" : ""}
                >
                  {t("sideMenu.seller.buttons.workingHours")}
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to={"#"}>{t("sideMenu.seller.buttons.commands")}</Link>
            <ul>
              <li>
                <Link
                  to={DASHBOARD_KITCHENS_COMMANDS_AT_KITCHEN}
                  onClick={() => closeMenu(setOpen)}
                  className={
                    sellerRouter.atKitchenCommands ? "active-link" : ""
                  }
                >
                  {t("sideMenu.seller.buttons.atKitchenCommands")}
                </Link>
              </li>
              <li>
                <Link
                  to={DASHBOARD_KITCHENS_COMMANDS_COOKED}
                  onClick={() => closeMenu(setOpen)}
                  className={sellerRouter.cookedCommands ? "active-link" : ""}
                >
                  {t("sideMenu.seller.buttons.cookedCommands")}
                </Link>
              </li>
              <li>
                <Link
                  to={DASHBOARD_KITCHENS_COMMANDS_SERVED}
                  onClick={() => closeMenu(setOpen)}
                  className={sellerRouter.servedCommands ? "active-link" : ""}
                >
                  {t("sideMenu.seller.buttons.servedCommands")}
                </Link>
              </li>
            </ul>
          </li>
          <hr />
          <li>
            <Link
              to="/"
              onClick={() => {
                closeMenu(setOpen);
                logout();
              }}
            >
              {t("sideMenu.seller.buttons.signOff")}
            </Link>
          </li>
          <li>
            <Link to={{}} className="active-link">
              {t("sideMenu.version")}: {process.env.REACT_APP_VERSION}
            </Link>
          </li>
        </ul>
      </aside>
    </ContainerSideMenu>
  );
};

export const SellerNavbarButtons: React.FunctionComponent<{
  setOpen: SetOpen;
}> = ({ setOpen }) => {
  const { t } = useTranslation();
  const kitchens = useKitchenSelector();
  const kitchen = kitchens?.[0];

  const dashboardDishesIsExact =
    useRouteMatch({
      path: DASHBOARD_KITCHENS_DISHES,
      strict: true,
      sensitive: true,
    })?.isExact || false;

  const dashboardBusinessIsExact =
    useRouteMatch({
      path: DASHBOARD_KITCHENS,
      strict: true,
      sensitive: true,
    })?.isExact || false;

  return (
    <>
      {(dashboardDishesIsExact || dashboardBusinessIsExact) && (
        <Link
          className="button is-primary mx-2"
          to={
            dashboardDishesIsExact
              ? getKitchenUrl(kitchen?.id)
              : dashboardBusinessIsExact
              ? getKitchenDetailsUrl(kitchen?.id)
              : "/"
          }
        >
          <span className="icon is-small">
            <i className="fa-solid fa-up-right-from-square"></i>
          </span>
          <span className="is-hidden-mobile">
            {dashboardDishesIsExact
              ? t("view.dishes.navbarButton")
              : dashboardBusinessIsExact
              ? t("view.restaurant.navbarButton")
              : "/"}
          </span>
        </Link>
      )}
      <button
        className="button mr-1 is-primary"
        onClick={() => {
          setOpen((prev) => !prev);
        }}
      >
        <span className="icon">
          <i className="fa-solid fa-bars"></i>
        </span>
      </button>
    </>
  );
};

export const SellerNavbarText: React.FunctionComponent<{
  user: UserFull;
}> = (props) => {
  const { t } = useTranslation();

  const dashboardDishesIsExact =
    useRouteMatch({
      path: DASHBOARD_KITCHENS_DISHES,
      strict: true,
      sensitive: true,
    })?.isExact || false;

  const dashboardBusinessIsExact =
    useRouteMatch({
      path: DASHBOARD_KITCHENS,
      strict: true,
      sensitive: true,
    })?.isExact || false;

  return (
    <>
      {!dashboardDishesIsExact && !dashboardBusinessIsExact ? (
        <div>
          <p className="has-text-weight-semibold has-text-centered">
            <span
              dangerouslySetInnerHTML={{
                __html:
                  t("sideMenu.seller.welcomeMessage", {
                    name: props.user.extended.first_name,
                  }) || "",
              }}
            />
          </p>
        </div>
      ) : null}
    </>
  );
};
