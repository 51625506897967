import { AnyAction } from "redux";

import { PAGE_ACTION, PAGE_REDUCERS } from "./actions";

const initialState = {
  [PAGE_REDUCERS.accounts]: {
    isLoading: false,
  },
  [PAGE_REDUCERS.categories]: {
    isLoading: false,
  },
  [PAGE_REDUCERS.commands]: {
    isLoading: false,
  },
  [PAGE_REDUCERS.dishes]: {
    isLoading: false,
  },
  [PAGE_REDUCERS.kitchens]: {
    isLoading: false,
  },
  [PAGE_REDUCERS.newsletters]: {
    isLoading: false,
  },
  [PAGE_REDUCERS.users]: {
    isLoading: false,
  },
  [PAGE_REDUCERS.forms]: {},
  [PAGE_REDUCERS.session]: {},
} as const;

const setReducerLoading = (state: typeof initialState, action: AnyAction) => {
  if (action.payload.formName) {
    return setFormLoading(state, action);
  }
  return setTableLoading(state, action);
};

const setSession = (state: typeof initialState, action: AnyAction) => {
  return {
    ...state,
    [PAGE_REDUCERS.session]: {
      ...action.payload.user,
    },
  };
};

const setPagination = (state: typeof initialState, action: AnyAction) => {
  const tableName = action.payload.tableName;
  const pageState = { ...(state as any) };
  const individualState = { ...pageState[tableName] };
  return {
    ...pageState,
    [tableName]: {
      ...individualState,
      meta: action.payload.meta,
      links: action.payload.links,
    },
  };
};

const Reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case PAGE_ACTION.SET_ACCOUNTS_LOADING:
    case PAGE_ACTION.SET_CATEGORIES_LOADING:
    case PAGE_ACTION.SET_COMMANDS_LOADING:
    case PAGE_ACTION.SET_DISHES_LOADING:
    case PAGE_ACTION.SET_KITCHENS_LOADING:
    case PAGE_ACTION.SET_NEWSLETTERS_LOADING:
    case PAGE_ACTION.SET_USERS_LOADING:
    case PAGE_ACTION.SET_FORMS_LOADING:
      return setReducerLoading(state, action);
    case PAGE_ACTION.SET_SESSION:
      return setSession(state, action);
    case PAGE_ACTION.SET_CATEGORIES_PAGINATION:
    case PAGE_ACTION.SET_DISHES_PAGINATION:
      return setPagination(state, action);
    default:
      return state;
  }
};

const setTableLoading = (state: typeof initialState, action: AnyAction) => {
  const tableName = action.payload.tableName;
  const pageState = { ...(state as any) };
  const individualState = { ...pageState[tableName] };
  return {
    ...pageState,
    [tableName]: { ...individualState, isLoading: action.payload.isLoading },
  };
};

const setFormLoading = (state: typeof initialState, action: AnyAction) => {
  const formName = action.payload.formName;
  const pageState = { ...(state as any) };
  const formState = pageState[PAGE_REDUCERS.forms][formName];
  return {
    ...pageState,
    [PAGE_REDUCERS.forms]: {
      [formName]: { ...formState, isLoading: action.payload.isLoading },
    },
  };
};

export default Reducer;
