import { useDispatch, useSelector } from "react-redux";

import {
  apiValidateJoinFirstStepForm,
  apiValidateJoinSecondStepForm,
} from "../../api/validation";
import { apiPostJoin } from "../../api/join";
import {
  apiPostInitialData,
  apiPostLogin,
  apiPostLogout,
} from "../../api/login";
import { AppThunk, RootStateType } from "../Main";
import { setFormsLoading, setSession } from "./actions";
import { setUserFull } from "../users/actions";
import { setAccount } from "../accounts/actions";
import { setKitchen } from "../kitchens/actions";
import { removeUserData, storeUserData } from "../../api/utils";

import { PAGE_REDUCERS } from "./actions";
import { IFirstStepResponse } from "../../components/forms/JoinFirstStep";
import { ISecondStepKitchenResponse } from "../../components/forms/JoinSecondStep";
import { IAddressResponse } from "../../components/forms/AddressSelector";
import { UserFull } from "../../types/user";

const JOIN_FIRST_STEP = "joinFirstStep";
const JOIN_SECOND_STEP = "joinSecondStep";
const JOIN_ADDRESS_STEP = "joinAddressStep";
const LOAD_INITIAL_DATA = "loadInitialData";
const LOGIN_REQUEST = "loginRequest";
const LOGOUT_REQUEST = "logoutRequest";

export const useSessionSelector = (): UserFull | undefined => {
  return useSelector((state: RootStateType) => state._page?.session);
};

export const useJoinFirstStepFormSelector = () => {
  return useSelector(
    (state: RootStateType) =>
      state._page[PAGE_REDUCERS.forms]?.[JOIN_FIRST_STEP]
  );
};

const runApiValidateJoinFirstStepForm =
  (form: Record<string, string>): AppThunk =>
  async (dispatch) => {
    await dispatch(setFormsLoading(JOIN_FIRST_STEP, true));
    try {
      const res = await apiValidateJoinFirstStepForm(form);
      await dispatch(setFormsLoading(JOIN_FIRST_STEP, false));
      if (res.status === 400) {
        return { data: res.data, status: res.status };
      } else if (res.status >= 300) {
        throw new Error(JSON.stringify(res));
      }
      return { data: res.data, status: res.status };
    } catch (err) {
      await dispatch(setFormsLoading(JOIN_FIRST_STEP, false));
      throw err;
    }
  };

export const useApiValidateJoinFirstStepForm = () => {
  const dispatch = useDispatch();
  return (form: Record<string, string>) =>
    dispatch(runApiValidateJoinFirstStepForm(form));
};

export const useJoinSecondStepFormSelector = () => {
  return useSelector(
    (state: RootStateType) =>
      state._page[PAGE_REDUCERS.forms]?.[JOIN_SECOND_STEP]
  );
};

const runApiValidateJoinSecondStepForm =
  (form: Record<string, string>): AppThunk =>
  async (dispatch) => {
    await dispatch(setFormsLoading(JOIN_SECOND_STEP, true));
    try {
      const res = await apiValidateJoinSecondStepForm(form);
      await dispatch(setFormsLoading(JOIN_SECOND_STEP, false));
      if (res.status === 400) {
        return { data: res.data, status: res.status };
      } else if (res.status >= 300) {
        throw new Error(JSON.stringify(res));
      }
      return { data: res.data, status: res.status };
    } catch (err) {
      await dispatch(setFormsLoading(JOIN_SECOND_STEP, false));
      throw err;
    }
  };

export const useApiValidateJoinSecondStepForm = () => {
  const dispatch = useDispatch();
  return (form: Record<string, string>) =>
    dispatch(runApiValidateJoinSecondStepForm(form));
};

const runApiPostJoin =
  (
    form: IFirstStepResponse & ISecondStepKitchenResponse & IAddressResponse
  ): AppThunk =>
  async (dispatch) => {
    await dispatch(setFormsLoading(JOIN_ADDRESS_STEP, true));
    try {
      const res = await apiPostJoin(form);
      await dispatch(setFormsLoading(JOIN_ADDRESS_STEP, false));
      if (res.status === 400) {
        return { data: res.data, status: res.status };
      } else if (res.status >= 300) {
        throw new Error(JSON.stringify(res));
      }
      storeUserData({
        token: res.data.token,
        type: res.data.user.permissions,
        name: res.data.user.extended.first_name || res.data.user.username,
        username: res.data.user.username,
      });
      await dispatch(setSession(res.data));
      await dispatch(setUserFull(res.data.user));
      await dispatch(setAccount(res.data.account));
      await dispatch(setKitchen(res.data.kitchen));
      return { data: res.data, status: res.status };
    } catch (err) {
      await dispatch(setFormsLoading(JOIN_ADDRESS_STEP, false));
      throw err;
    }
  };

export const useApiPostJoin = () => {
  const dispatch = useDispatch();
  return (
    form: IFirstStepResponse & ISecondStepKitchenResponse & IAddressResponse
  ) => dispatch(runApiPostJoin(form));
};

export const useLoginFormSelector = () => {
  return useSelector(
    (state: RootStateType) => state._page[PAGE_REDUCERS.forms]?.[LOGIN_REQUEST]
  );
};

const runApiPostLogin =
  (form: { email: string; password: string }): AppThunk =>
  async (dispatch) => {
    await dispatch(setFormsLoading(LOGIN_REQUEST, true));
    try {
      const res = await apiPostLogin(form);
      await dispatch(setFormsLoading(LOGIN_REQUEST, false));
      if (res.status === 400) {
        return { data: res.data, status: res.status };
      } else if (res.status >= 300) {
        throw new Error(JSON.stringify(res));
      }
      storeUserData({
        token: res.data.token,
        type: res.data.user.permissions,
        name: res.data.user.extended.first_name || res.data.user.username,
        username: res.data.user.username,
      });
      await dispatch(setSession(res.data));
      await dispatch(setUserFull(res.data.user));
      await dispatch(setAccount(res.data.account));
      await dispatch(setKitchen(res.data.kitchen));
      return { data: res.data, status: res.status };
    } catch (err) {
      await dispatch(setFormsLoading(LOGIN_REQUEST, false));
      throw err;
    }
  };

export const useApiPostLogin = () => {
  const dispatch = useDispatch();
  return (form: { email: string; password: string }) =>
    dispatch(runApiPostLogin(form));
};

const runApiPostLogout = (): AppThunk => async (dispatch) => {
  await dispatch(setFormsLoading(LOGOUT_REQUEST, true));
  try {
    const res = await apiPostLogout();
    await dispatch(setFormsLoading(LOGOUT_REQUEST, false));
    if (res.status === 400) {
      return { data: res.data, status: res.status };
    } else if (res.status >= 300) {
      throw new Error(JSON.stringify(res));
    }
    removeUserData();
    // window.location.reload();
    return { data: res.data, status: res.status };
  } catch (err) {
    await dispatch(setFormsLoading(LOGOUT_REQUEST, false));
    throw err;
  } finally {
    removeUserData();
    window.location.reload();
  }
};

export const useApiPostLogout = () => {
  const dispatch = useDispatch();
  return () => dispatch(runApiPostLogout());
};

export const useLogoutSelector = () => {
  return useSelector(
    (state: RootStateType) => state._page[PAGE_REDUCERS.forms]?.[LOGOUT_REQUEST]
  );
};

const runApiPostInitialData = (): AppThunk => async (dispatch) => {
  await dispatch(setFormsLoading(LOAD_INITIAL_DATA, true));
  try {
    const res = await apiPostInitialData();
    await dispatch(setFormsLoading(LOAD_INITIAL_DATA, false));
    if (res.status >= 300) {
      // Unauthorized
      if (res.status === 401) {
        await dispatch(runApiPostLogout());
      }
      throw new Error(JSON.stringify(res));
    }
    await dispatch(setSession(res.data));
    await dispatch(setUserFull(res.data.user));
    await dispatch(setAccount(res.data.account));
    await dispatch(setKitchen(res.data.kitchen));
    return { data: res.data, status: res.status };
  } catch (err) {
    await dispatch(setFormsLoading(LOAD_INITIAL_DATA, false));
    throw err;
  }
};

export const useApiPostInitialData = () => {
  const dispatch = useDispatch();
  return () => dispatch(runApiPostInitialData());
};

export const useInitialDataSelector = () => {
  return useSelector(
    (state: RootStateType) =>
      state._page[PAGE_REDUCERS.forms]?.[LOAD_INITIAL_DATA]
  );
};
