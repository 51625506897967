import { useSelector, useDispatch } from "react-redux";

import { RootStateType, AppThunk } from "../Main";
import { setCommands } from "./actions";
import { PAGE_REDUCERS, setCommandsLoading } from "../_page/actions";
import { Command } from "../../types/command.d";
import {
  apiDeleteActiveCommand,
  apiGetActiveCommand,
  apiUpdateActiveCommand,
} from "../../api/commands";
import { IPaginationResponse } from "../../api/utils";

export const useCommandSelector = (): Command => {
  return useSelector((state: RootStateType) => state.commands?.[0] || {});
};

export const useCommandPageSelector = (): IPaginationResponse & {
  isLoading: boolean;
} => {
  return useSelector(
    (state: RootStateType) => state._page[PAGE_REDUCERS.commands]
  );
};

const runApiGetActiveCommand =
  (kitchenId: string, table: number): AppThunk =>
  async (dispatch) => {
    await dispatch(setCommandsLoading(true));
    try {
      const res = await apiGetActiveCommand(kitchenId, table);
      await dispatch(setCommandsLoading(false));
      if (res.status >= 300) {
        await dispatch(setCommands([]));
        throw new Error(JSON.stringify(res));
      }
      await dispatch(setCommands([res.data]));
    } catch (err) {
      await dispatch(setCommandsLoading(false));
      throw err;
    }
  };

export const useApiGetActiveCommand = () => {
  const dispatch = useDispatch();
  return (kitchenId: string, table: number) =>
    dispatch(runApiGetActiveCommand(kitchenId, table));
};

export const runApiUpdateActiveCommand =
  (
    partialKitchen: Partial<Command>,
    kitchenId: string,
    commandId?: string
  ): AppThunk =>
  async (dispatch) => {
    await dispatch(setCommandsLoading(true));
    try {
      const res = await apiUpdateActiveCommand(
        partialKitchen,
        kitchenId,
        commandId
      );
      await dispatch(setCommandsLoading(false));
      if (res.status === 400) {
        return { data: res.data, status: res.status };
      } else if (res.status >= 300) {
        await dispatch(setCommands([]));
        throw new Error(JSON.stringify(res));
      }
      await dispatch(setCommands([res.data]));
      return { data: res.data, status: res.status };
    } catch (err) {
      await dispatch(setCommandsLoading(false));
      throw err;
    }
  };

export const useApiUpdateActiveCommand = (
  kitchenId: string,
  commandId?: string
) => {
  const dispatch = useDispatch();
  return (partialCommand: Partial<Command>) =>
    dispatch(runApiUpdateActiveCommand(partialCommand, kitchenId, commandId));
};

const runApiDeleteActiveCommand =
  (kitchenId: string, commandId: string): AppThunk =>
  async (dispatch) => {
    await dispatch(setCommandsLoading(true));
    try {
      const res = await apiDeleteActiveCommand(kitchenId, commandId);
      await dispatch(setCommandsLoading(false));
      if (res.status >= 300) {
        await dispatch(setCommands([]));
        throw new Error(JSON.stringify(res));
      }
      await dispatch(setCommands([]));
    } catch (err) {
      await dispatch(setCommandsLoading(false));
      throw err;
    }
  };

export const useApiDeleteActiveCommand = () => {
  const dispatch = useDispatch();
  return (kitchenId: string, commandId: string) =>
    dispatch(runApiDeleteActiveCommand(kitchenId, commandId));
};
