import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Modal from "../../layouts/Modal";
import AppContext from "../../store/context/App";
import { getUrl } from "../../utils/url";

const ModalInviteRegistration: React.FunctionComponent<{
  modalId: string;
}> = (props) => {
  const { appContextDispatch } = React.useContext(AppContext);
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const url = getUrl();
  const modalName = url.searchParams.get("modal");
  React.useEffect(() => {
    if (modalName === "invite-to-register") {
      setIsOpen(true);
    }
  }, [modalName]);

  return (
    <Modal
      modalId={props.modalId}
      queryParam="invite-to-register"
      open={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <div className="modal-content" style={{ maxWidth: "100wv" }}>
        <div className="modal-card mx-0" style={{ maxWidth: "100wv" }}>
          <header className="modal-card-head">
            <video autoPlay loop muted>
              <source type="video/mp4" src="/hadamenu.mp4" />
            </video>
          </header>
          <section className="modal-card-body">
            <div
              className="content is-normal"
              dangerouslySetInnerHTML={{
                __html: t("modals.registerInvite.bodyText") || "",
              }}
            ></div>
          </section>
          <footer className="modal-card-foot">
            <Link
              to={{
                search: "?modal=signup",
              }}
              onClick={() => {
                appContextDispatch({
                  type: "OPEN_JOIN_MODAL",
                });
              }}
              className="button is-success"
            >
              {t("modals.registerInvite.footerButtons.register")}
            </Link>
            <button className="button">
              {t("modals.registerInvite.footerButtons.close")}
            </button>
          </footer>
        </div>
      </div>
    </Modal>
  );
};

export default ModalInviteRegistration;
