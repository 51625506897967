export interface IPaginationResponse {
  links: {
    first: string;
    last: string | null;
    prev: string | null;
    next: string | null;
  };
  meta: {
    current_page: number;
    from: number;
    path: string;
    per_page: number;
    to: number;
  };
}

enum USER {
  TOKEN = "USER_TOKEN",
  NAME = "USER_NAME",
  USERNAME = "USERNAME",
  TYPE = "USER_TYPE",
}

export const setUserToken = (token: string): void => {
  return localStorage.setItem(USER.TOKEN, token);
};

export const getUserToken = (): string | null => {
  return localStorage.getItem(USER.TOKEN) || null;
};

export const removeUserToken = (): void => {
  return localStorage.removeItem(USER.TOKEN);
};

export const setUserType = (type: string): void => {
  return localStorage.setItem(USER.TYPE, type);
};

export const getUserType = (): string | null => {
  return localStorage.getItem(USER.TYPE) || null;
};

export const removeUserType = (): void => {
  return localStorage.removeItem(USER.TYPE);
};

export const setName = (name: string): void => {
  return localStorage.setItem(USER.NAME, name);
};

export const getName = (): string | null => {
  return localStorage.getItem(USER.NAME) || null;
};

export const removeName = (): void => {
  return localStorage.removeItem(USER.NAME);
};

export const setUsername = (username: string): void => {
  return localStorage.setItem(USER.USERNAME, username);
};

export const getUsername = (): string | null => {
  return localStorage.getItem(USER.USERNAME) || null;
};

export const removeUsername = (): void => {
  return localStorage.removeItem(USER.USERNAME);
};

export const storeUserData = ({
  token,
  type,
  name,
  username,
}: Record<string, string>): void => {
  setUserToken(token);
  setUserType(type);
  setName(name);
  setUsername(username);
  return;
};

export const getUserData = (): Record<string, string | null> => ({
  token: getUserToken(),
  type: getUserType(),
  name: getName(),
  username: getUsername(),
});

export const removeUserData = (): void => {
  removeUserToken();
  removeUserType();
  removeName();
  removeUsername();
  return;
};
