export const footer = {
  sections: {
    subscribe: {
      title: "Mantente conectado para actualizaciones u ofertas especiales.",
      button: "Suscribirse",
    },
    links: {
      company: {
        title: "Compañia",
        whoAreWe: "¿Quienes somos?",
        pricing: "Precio",
        faq: "Preguntas frecuentes",
      },
      profile: {
        title: "Perfil",
        myProducts: "Mis productos",
        myProfile: "Mi perfil",
      },
      termsAndPolicies: {
        terms: "Términos de uso",
        privacy: "Política de privacidad",
      },
    },
    copyRight: "Todos los derechos reservados",
  },
};
