import { apiFetch } from "../utils/api";
import { AxiosResponse } from "axios";
import { Command, COMMAND_STATUS } from "../types/command.d";
import { isNaN } from "lodash";
import { getUserToken } from "./utils";

export const apiUpdateActiveCommand = async (
  data: Partial<Command>,
  kitchenId: string,
  commandId?: string
): Promise<AxiosResponse<Command>> => {
  if (!kitchenId) {
    return {
      data: {} as any,
      status: 500,
      statusText: "Kitchen ID not passed",
      headers: {},
      config: {},
    };
  }
  const source = commandId
    ? apiFetch(`/kitchens/${kitchenId}/commands/${commandId}`)
    : apiFetch(`/kitchens/${kitchenId}/commands`);
  try {
    const response = await source.create(data);
    return response;
  } catch (err: any) {
    return err.response;
  }
};

export const apiGetActiveCommand = async (
  kitchenId: string,
  table: number
): Promise<AxiosResponse<Command>> => {
  if (!isNaN(table) && table <= 0) {
    return {
      data: {} as any,
      status: 500,
      statusText: "Table not passed",
      headers: {},
      config: {},
    };
  }
  if (!kitchenId) {
    return {
      data: {} as any,
      status: 500,
      statusText: "Kitchen ID not passed",
      headers: {},
      config: {},
    };
  }
  const source = apiFetch(`/kitchens/${kitchenId}/commands?table=${table}`);
  try {
    const response = await source.read();
    return response;
  } catch (err: any) {
    return err.response;
  }
};

export const apiGetCommand = async (
  commandId: string
): Promise<AxiosResponse<Command>> => {
  const source = apiFetch(`/kitchens/undefined/commands?id=${commandId}`);
  try {
    const response = await source.read();
    return response;
  } catch (err: any) {
    return err.response;
  }
};

export const apiGetCommandsByStatus = async (
  status: string,
  {
    startOfDay,
  }: {
    startOfDay?: string;
  }
): Promise<AxiosResponse<Command[]>> => {
  const startOfDayQuery = startOfDay ? `startOfDay=${startOfDay}` : undefined;
  const source = apiFetch(
    `/commands/${status}/status${startOfDayQuery ? "?" + startOfDayQuery : ""}`
  );
  try {
    const authToken = getUserToken();
    const response = await source.read({
      Authorization: authToken,
    });
    return response;
  } catch (err: any) {
    return err.response;
  }
};

export const apiUpdateCommandFromKitchen = async (
  commandId: string,
  data: Partial<Command & { current_status: COMMAND_STATUS }>
): Promise<AxiosResponse<Command>> => {
  const source = apiFetch(`/commands/${commandId}`);
  try {
    const authToken = getUserToken();
    const response = await source.update(data, {
      Authorization: authToken,
    });
    return response;
  } catch (err: any) {
    return err.response;
  }
};

export const apiGetCommands = async (
  commandIds: string
): Promise<AxiosResponse<Command[]>> => {
  const source = apiFetch(`/kitchens/undefined/commands?ids=${commandIds}`);
  try {
    const response = await source.read();
    return response;
  } catch (err: any) {
    return err.response;
  }
};

export const apiDeleteActiveCommand = async (
  kitchenId: string,
  commandId: string
): Promise<AxiosResponse<boolean>> => {
  const source = apiFetch(`/kitchens/${kitchenId}/commands/${commandId}`);
  try {
    const response = await source.delete();
    return response;
  } catch (err: any) {
    return err.response;
  }
};
