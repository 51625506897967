import { Image } from "./image";

export enum COMMAND_STATUS {
  WAITING = "waiting",
  FINISHED = "finished",
  KITCHEN = "kitchen",
  ON_THE_WAY = "on_the_way",
  CANCELLED = "cancelled",
  REFUNDED = "refunded",
  REFUNDED_AND_CANCELLED = "refunded_and_cancelled",
  PAID = "paid",
}

export enum COMMAND_ACTION {
  SET_COMMANDS = "SET_COMMANDS",
}

export interface Order {
  id: string;
  name: string;
  cost: number;
  quantity: number;
  instructions?: string;
  images?: Image[];
}

export interface Command {
  id: string;
  order: Order[];
  cost: number;
  table: number;
  status: COMMAND_STATUS;
  instructions: string;
  cancellation_reason: string;
  created_at: number;
  updated_at: number;
}
