export const esViewSeller = {
  informative:
    "Esta es información 100% personal que no será compartida con tus clientes.",
  account: {
    program: {
      free: "gratis",
      giving_you_a_hand: "te damos la mano",
      growing_with_you: "crecemos contigo",
    },
  },
};
