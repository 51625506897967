import React from "react";

import AppContext from "../../store/context/App";
import InputField from "../utils/InputField";
import { getInputId } from "../../utils/components";
import {
  useApiPostNewsletter,
  useNewsLetterFormSelector,
} from "../../store/newsletters/utils";
import {
  reducerForm,
  handleInputChange,
  handleSubmit,
  useCleanFormInputs,
} from "./utils";
import { Newsletter } from "../../types/newsletter";
import { useTranslation } from "react-i18next";

export interface ISubscribeResponse {
  email: Newsletter["email"];
  is_authorized: Newsletter["is_authorized"];
  updated_at: Newsletter["updated_at"];
}

enum INPUT {
  EMAIL = "email",
}

const initialState = {
  [INPUT.EMAIL]: "",
  errors: {} as Record<string, string>,
} as const;

const SubscribeForm: React.FunctionComponent<{
  // Server's response
  submitted?: (data: ISubscribeResponse) => void;
  submitting?: Function;
  initialData?: ISubscribeResponse;
}> = (props) => {
  const { t } = useTranslation();
  const inputIds = React.useMemo(
    () => ({
      email: getInputId(INPUT.EMAIL),
    }),
    []
  );

  const { appContextDispatch } = React.useContext(AppContext);
  const [state, dispatch] = React.useReducer(reducerForm, initialState);
  const formState = useNewsLetterFormSelector();
  const sendForm = useApiPostNewsletter();

  const handleOnSubmit = async (form: Record<string, string>) => {
    await handleSubmit(props, form, sendForm, {
      inApp: appContextDispatch,
      redux: dispatch,
      initialState,
      t,
    });
  };

  useCleanFormInputs(inputIds, state);

  return (
    <React.Fragment>
      <InputField
        isRequired
        leftIcon="fa-solid fa-envelope"
        placeholder="maria@hadamenu.com"
        type="email"
        name={INPUT.EMAIL}
        id={inputIds.email}
        onChange={(e) => handleInputChange(dispatch, e)}
        errorText={
          !!state.errors?.[INPUT.EMAIL] ? state.errors?.[INPUT.EMAIL] : null
        }
      />

      <button
        onClick={() => handleOnSubmit(state)}
        className={`button is-fullwidth is-medium is-primary has-text-weight-bold${
          !!formState?.isLoading ? " is-loading" : ""
        }`}
      >
        {t("footer.sections.subscribe.button")}
      </button>
    </React.Fragment>
  );
};

export default SubscribeForm;
