import React, { useContext } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";

import AppContext from "../store/context/App";

function CustomAlert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Alert: React.FC = () => {
  const { appContextState, appContextDispatch } = useContext(AppContext);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    appContextDispatch({
      type: "ALERT_STATE",
      alertState: false,
      alertLevel: "success",
      alertMesssage: "",
    });
  };

  return (
    <Snackbar
      open={appContextState.alert}
      onClose={handleClose}
      autoHideDuration={6000}
    >
      <CustomAlert onClose={handleClose} severity={appContextState.alertLevel}>
        {appContextState.alertMessage}
      </CustomAlert>
    </Snackbar>
  );
};

export default Alert;
