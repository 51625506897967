import { AnyAction } from "redux";

import { DISH_ACTION, Dish } from "../../types/dish.d";
import {
  pushMultipleElementsToState,
  pushNewElementToState,
  updateElementInState,
} from "../utils";

const initialState = [] as Dish[];

const setDish = (state: typeof initialState, action: AnyAction) => {
  return pushNewElementToState(state, action.payload);
};

const setDishes = (state: typeof initialState, action: AnyAction) => {
  return pushMultipleElementsToState(state, action.payload);
};

const deleteDish = (state: typeof initialState, action: AnyAction) => {
  const dishId = action.payload;
  const dishesCloned = [...state];
  const updatedDishes = dishesCloned.filter((dish) => dish.id !== dishId);
  return updatedDishes;
};

const updateDish = (state: typeof initialState, action: AnyAction) => {
  return updateElementInState(state, action.payload);
};

const Reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case DISH_ACTION.SET_DISH:
      return setDish(state, action);
    case DISH_ACTION.SET_DISHES:
      return setDishes(state, action);
    case DISH_ACTION.UPDATE_DISH:
      return updateDish(state, action);
    case DISH_ACTION.DELETE_DISH:
      return deleteDish(state, action);
    default:
      return state;
  }
};

export default Reducer;
