import { apiFetch } from "../utils/api";
import { Newsletter } from "../types/newsletter";
import { AxiosResponse } from "axios";

export const apiPostNewsletter = async (
  data: Partial<Newsletter>
): Promise<AxiosResponse<Newsletter | Record<string, string[]>>> => {
  const source = apiFetch("/newsletters");
  try {
    const response = await source.create(data);
    return response;
  } catch (err: any) {
    return err.response;
  }
};
