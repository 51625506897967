import { apiFetch } from "../utils/api";
import { AxiosResponse } from "axios";
import { InitialInfo } from "../types/user";

export const apiPostJoin = async (
  data: any
): Promise<AxiosResponse<InitialInfo>> => {
  const source = apiFetch("/register");
  try {
    const response = await source.create(data);
    return response;
  } catch (err: any) {
    return err.response;
  }
};
