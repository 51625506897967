import { createTheme } from "@material-ui/core/styles";

export default createTheme({
  typography: {
    fontFamily: "Open Sans,sans-serif",
    body1: {
      fontSize: "20px",
      lineHeight: "28px",
      color: "#6e6e6f",
    },
    subtitle2: {
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.08125em",
      textTransform: "uppercase",
    },
  },
  palette: {
    primary: {
      main: "#ed0f93",
    },
    secondary: {
      main: "#ff360f",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        fontWeight: "bold",
        textTransform: "none",
      },
    },
  },
});
