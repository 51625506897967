import React from "react";
import { Link, useHistory } from "react-router-dom";
import { LANGUAGES } from "../constants";
import { useSessionSelector } from "../store/_page/utils";
import { useFirstAccountSelector } from "../store/accounts/utils";
import { useTranslation } from "react-i18next";
import {
  PublicSideMenu,
  PublicNavbarButtons,
  PublicNavbarText,
} from "../components/side-menu/PublicMenu";
import { scrollTop } from "../utils/scroll";
import {
  SellerNavbarButtons,
  SellerNavbarText,
  SellerSideMenu,
} from "../components/side-menu/SellerMenu";
import { USER_PERMISSION } from "../types/user.d";
import {
  CHECKOUT,
  DASHBOARD_ACCOUNTS,
  DASHBOARD_KITCHENS_DISHES,
} from "../constants/routes";
import {
  useCommandSelector,
  useCommandPageSelector,
} from "../store/commands/utils";
import { getUrl } from "../utils/url";
import { isNaN } from "lodash";

const Navbar: React.FunctionComponent = () => {
  const user = useSessionSelector();
  const account = useFirstAccountSelector();
  const history = useHistory();
  const { i18n } = useTranslation();
  const currentCommand = useCommandSelector();
  const isCommandLoading = useCommandPageSelector()?.isLoading;
  const [open, setOpen] = React.useState<boolean>(false);
  const isSellerAdmin = user?.permissions === USER_PERMISSION.SELLER_ADMIN;
  const languageWasSet = account?.language;
  const tableQueryParam = getUrl()?.searchParams.get("table");

  const logoUrl = Object.keys(user || {}).length
    ? DASHBOARD_KITCHENS_DISHES
    : "/";

  return (
    <>
      <nav className="navbar is-fixed-top is-primary is-flex is-justify-content-space-between is-align-items-center">
        <div className="navbar-brand">
          <Link className="navbar-item py-0" onClick={scrollTop} to={logoUrl}>
            <img
              src="/logo192.png"
              alt="logo"
              width="50"
              height="50"
              style={{ maxHeight: "none" }}
            />
          </Link>
        </div>
        {isSellerAdmin ? (
          <SellerNavbarText user={user} />
        ) : (
          <PublicNavbarText />
        )}
        <div>
          {currentCommand?.order &&
          tableQueryParam &&
          !isNaN(tableQueryParam) &&
          parseInt(tableQueryParam) > 0 ? (
            <Link
              to={CHECKOUT}
              className={`button mr-1 is-primary${
                isCommandLoading ? " is-loading" : ""
              }`}
              onClick={() => scrollTop()}
            >
              <span className="icon">
                <i className="fa-solid fa-cart-shopping"></i>
              </span>
              <span>{currentCommand?.order?.length}</span>
            </Link>
          ) : undefined}
          <button
            className="button mr-1 is-primary"
            onClick={() => {
              if (languageWasSet) {
                history.push(DASHBOARD_ACCOUNTS);
              } else {
                const languages = Object.keys(LANGUAGES);
                const languageSelected = languages.find(
                  (lang) => lang !== i18n.resolvedLanguage
                );
                i18n.changeLanguage(languageSelected);
              }
            }}
          >
            <span className="icon">
              <i className="fa-solid fa-globe"></i>
            </span>
            {!languageWasSet && (
              <span>{i18n.resolvedLanguage.toUpperCase()}</span>
            )}
          </button>
          {isSellerAdmin ? (
            <SellerNavbarButtons setOpen={setOpen} />
          ) : (
            <PublicNavbarButtons setOpen={setOpen} />
          )}
        </div>
      </nav>
      {isSellerAdmin ? (
        <SellerSideMenu open={open} setOpen={setOpen} />
      ) : (
        <PublicSideMenu open={open} setOpen={setOpen} />
      )}
    </>
  );
};

export default Navbar;
