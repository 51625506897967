export const viewHome = {
  sections: {
    mission: {
      label: "MISIÓN",
      description: "Empoderando a la mujer mexicana",
      text: `La jefatura femenina se ha incrementado en los últimos años por
      motivos económicos, sociales y psicológicos. Donde 28 de cada 100 mujeres
      ejercen su maternidad sin pareja. Siete de ellas son madres solteras y 21
      están separadas, viudas o divorciadas. Donde 4 de cada 10 tienen un empleo.
      Usemos "Hada Menú" y hagamos que niños como José tengan una mejor calidad
      de vida por medio de políticas dirigidas a reconciliar al trabajo y jefas
      de familia. Incorporandolas al ámbito laboral donde tengan el tiempo para
      el cuidado de bebés después del parto y con horarios flexibles que permitan
      cuidar de su familia sin afectar salarios y derechos.`,
    },
    categories: {
      label: "Comida que existe en nuestro catálogo",
      name: {
        tacos: "Tacos",
        cafe_o_te: "Café o té",
        regional: "Regional",
        sandwiches: "Sandwiches",
        alcohol: "Alcohol",
        pasteleria: "Pasteleria",
        bebidas: "Bebidas",
        ensaladas: "Ensaladas",
        mexicana: "Mexicana",
        pizzas: "Pizzas",
        mariscos: "Mariscos",
        panaderia: "Panaderia",
        hamburguesas: "Hamburguesas",
        sopas: "Sopas",
        pastas: "Pastas",
        postres: "Postres",
        otro: "Otro",
        desayunos: "Desayunos",
        alitas: "Alitas",
        entradas: "Entradas",
        para_compartir: "Para compartir",
      },
    },
  },
};
