export const esViewSchedules = {
  schedules: "Días laborales",
  day: {
    monday: "lunes",
    tuesday: "martes",
    wednesday: "miércoles",
    thursday: "jueves",
    friday: "viernes",
    saturday: "sábado",
    sunday: "domingo",
  },
  isItOpen: "¿Abierto?",
  startsAt: "Comienza",
  endsAt: "Termina",
  callForMoreInformation: "Llamar para más información",
  closed: "Cerrado",
};
