import { camelCase, isArray } from "lodash";
import { useEffect } from "react";

export enum STEP {
  FIRST = "first-step",
  SECOND = "second-step",
  LAST = "last-step",
  SUBMIT = "submit",
}

export enum ACTION_FORM {
  FIELD_CHANGED = "field_changed",
  FIELD_APPEND = "field_append",
  FIELD_ERROR = "field_error",
  RESET = "reset",
}

export const reducerForm = <T>(
  state: T,
  action: { type: ACTION_FORM; payload: any }
) => {
  const fieldName = Object.keys(action.payload)[0];
  const fieldValue = { ...action.payload }[fieldName];
  switch (action.type) {
    case ACTION_FORM.FIELD_APPEND:
      const stateValue = { ...(state as any) }[fieldName];
      if (isArray(stateValue)) {
        stateValue.push(action.payload[fieldName]);
      }
      return {
        ...state,
        [fieldName]: [...stateValue],
      };
    case ACTION_FORM.FIELD_CHANGED:
      return {
        ...state,
        [fieldName]: fieldValue,
      };
    case ACTION_FORM.FIELD_ERROR:
      return {
        ...state,
        errors: {
          ...(state as any).errors,
          [fieldName]: fieldValue,
        },
      };
    case ACTION_FORM.RESET:
      return {
        ...action.payload,
      };
    default:
      throw Error("Unknown action: " + action.type);
  }
};

export const handleInputClick = (
  dispatch: any,
  e: React.MouseEvent<HTMLInputElement, MouseEvent>
) => {
  dispatch({
    type: ACTION_FORM.FIELD_CHANGED,
    payload: { [e.currentTarget.name]: e.currentTarget.value },
  });

  dispatch({
    type: ACTION_FORM.FIELD_ERROR,
    payload: { [e.currentTarget.name]: "" },
  });
};

export const handleInputChange = (
  dispatch: any,
  e: React.ChangeEvent<
    HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
  >
) => {
  dispatch({
    type: ACTION_FORM.FIELD_CHANGED,
    payload: { [e.target.name]: e.target.value },
  });

  dispatch({
    type: ACTION_FORM.FIELD_ERROR,
    payload: { [e.target.name]: "" },
  });
};

export const handleSubmit = async (
  props: Record<string, any>,
  form: Record<string, string>,
  validateForm: any,
  dispatch: {
    inApp: any;
    redux: any;
    initialState?: any;
    credentials?: any;
    t?: any;
    statusMessages?: {
      "400"?: string;
      "success"?: string;
    };
  }
) => {
  try {
    dispatch.inApp({
      type: "ALERT_STATE",
      alertState: false,
    });
    delete form.errors;
    props?.submitting?.();

    const res = (await validateForm(form)) as any;
    if (res.status === 400) {
      for (const fieldName in res.data) {
        dispatch.redux({
          type: ACTION_FORM.FIELD_ERROR,
          payload: { [fieldName]: res.data?.[fieldName]?.join(" ") },
        });
      }
      return dispatch.inApp({
        type: "ALERT_STATE",
        alertState: true,
        alertLevel: "warning",
        alertMessage:
          dispatch?.t?.(
            dispatch.statusMessages?.["400"]
              ? dispatch.statusMessages?.["400"]
              : "forms.errors.checkInputs"
          ) || "Revisa los campos, por favor",
      });
    }
    if (dispatch.initialState) {
      dispatch.redux({
        type: ACTION_FORM.RESET,
        payload: dispatch.initialState,
      });
    }
    props?.submitted?.(res.data);
  } catch (err) {
    if (
      (err as Error).message.includes("The provided credentials are incorrect")
    ) {
      dispatch.inApp({
        type: "ALERT_STATE",
        alertState: true,
        alertLevel: "error",
        alertMessage:
          dispatch?.t?.("forms.errors.checkCredentials") ||
          "Algo salió mal, revisa credenciales",
      });
    } else {
      dispatch.inApp({
        type: "ALERT_STATE",
        alertState: true,
        alertLevel: "error",
        alertMessage:
          dispatch?.t?.("forms.errors.serverError") ||
          "Algo salió mal, por favor intenta más tarde",
      });
    }
    throw err;
  }
};

export const setInitialData = (
  data: any,
  eInput: any,
  inputIds: any,
  dispatch: any
) => {
  if (data) {
    for (const fieldName in data) {
      if (
        Object.values(eInput).includes(fieldName as any) &&
        fieldName !== eInput.PERMISSIONS
      ) {
        let fieldValue = (data as any)[fieldName];
        if (fieldName === eInput.COST) {
          fieldValue = (parseInt(fieldValue) / 100).toFixed(2);
        }
        if (fieldName === eInput.IS_AVAILABLE) {
          fieldValue = fieldValue === true ? "true" : "false";
        }
        dispatch({
          type: ACTION_FORM.FIELD_CHANGED,
          payload: { [fieldName]: fieldValue },
        });
        const id = (inputIds as any)[camelCase(fieldName)];
        const element = document.getElementById(id) as HTMLInputElement;
        if (element) {
          element.value = fieldValue;
        }
      }
    }
  }
};

export const useCleanFormInputs = (inputIds: any, reducerState: any) => {
  useEffect(() => {
    for (const field in inputIds) {
      const reducerInputValue = reducerState[field];
      if (!reducerInputValue) {
        const inputElement = document.getElementById(
          inputIds[field]
        ) as HTMLInputElement;
        if (inputElement) {
          inputElement.value = "";
        }
      }
    }
  }, [reducerState, inputIds]);
};

export const selectCountryCode = (
  elementId: string,
  selectedCountryCode: any,
  { i18n, dispatch }: { i18n: any; dispatch: any }
) => {
  const countryCodeEl = document.getElementById(elementId) as HTMLSelectElement;
  if (countryCodeEl) {
    const selectOpts = countryCodeEl.getElementsByTagName("option");
    for (const selectOptIndex in selectOpts) {
      const option = countryCodeEl.options?.[selectOptIndex];
      if (selectedCountryCode && option.value === selectedCountryCode) {
        option.selected = true;
        dispatch({
          type: ACTION_FORM.FIELD_CHANGED,
          payload: { [countryCodeEl.name]: option.value },
        });
        return;
      }
      const ussNumber = option.value === "+1" && i18n.resolvedLanguage === "en";
      const mxNumber = option.value === "+52" && i18n.resolvedLanguage === "es";
      if (ussNumber || mxNumber) {
        option.selected = true;
        dispatch({
          type: ACTION_FORM.FIELD_CHANGED,
          payload: { [countryCodeEl.name]: option.value },
        });
      }
    }
  }
};

export const selectGender = (
  elementId: string,
  selectGender: string,
  { dispatch }: { dispatch: any }
) => {
  const selectEl = document.getElementById(elementId) as HTMLSelectElement;
  const selectOpts = selectEl?.getElementsByTagName("option");
  selectEl?.options.remove(0);
  for (const selectOptIndex in selectOpts) {
    const option = selectEl?.options?.[selectOptIndex];
    if (option.text?.toLowerCase() === selectGender.toLowerCase()) {
      option.selected = true;
      dispatch({
        type: ACTION_FORM.FIELD_CHANGED,
        payload: { [selectEl.name]: option.value },
      });
    }
  }
};
