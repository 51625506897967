export const sideMenu = {
  public: {
    buttons: {
      home: "Home",
      signup: "Sign up",
      signin: "Sign in",
      previousOrders: "Previous orders",
    },
    welcomeMessage: "Welcome",
  },
  seller: {
    buttons: {
      myProducts: "Dishes",
      account: "Account settings",
      business: "Kitchen settings",
      workingHours: "Working hours",
      profile: "Profile settings",
      signOff: "Sign off",
      commands: "Commands",
      atKitchenCommands: "At kitchen",
      cookedCommands: "Ready to serve",
      servedCommands: "Served",
    },
    welcomeMessage: "<span>Hello 👋,</span><br /><span>{{name}}</span>",
  },
  version: "Version",
};
