import { sideMenu } from "./sideMenu";

export const modals = {
  registerInvite: {
    bodyText: `<p>
        Do you want to start your own business? Do you already have a
        small business? Maybe you already have a big restaurant, but you
        haven't <strong>digitized your menu</strong> yet 🥲.
      </p>
      <p>
        No matter what the size of your business is 🎉,
        <strong>"Hada Menu"</strong> is the solution to share and sell
        food online 🍔.
      </p>`,
    footerButtons: {
      register: `${sideMenu.public.buttons.signup}, now!`,
      close: "I'm already registered",
    },
  },
  login: {
    title: sideMenu.public.buttons.signin,
    form: {},
    footer: "Don't wait longer!",
    footerButton: "Register here",
  },
  join: {
    title: {
      stepOne: sideMenu.public.buttons.signup,
      stepTwo: "Tell us a little more...",
      addressStep: "Where is your business located at?",
      submitStep: "Storing...",
    },
    welcome: "Welcome{{userName}}",
    footer: "🎉 Already have an account?",
    footerButton: "Login here",
  },
  addDish: {
    title: "New dish",
    close: "Close"
  },
};
