import { Account } from "./account";
import { Phone } from "./phone";
import { Image } from "./image";
import { Address } from "./address";
import { Kitchen } from "./kitchen";

export enum USER_ACTION {
  SET_USER_MINIMUM = "SET_USER_MINIMUM",
  UPDATE_USER_EXTENDED = "UPDATE_USER_EXTENDED",
  SET_USER_FULL = "SET_USER_FULL",
}

export enum USER_GENDER {
  MALE = "male",
  FEMALE = "female",
  NONE = "none",
}

export enum USER_PERMISSION {
  SUPER = "super",
  BUYER = "buyer",
  SELLER_ADMIN = "seller_admin",
  SELLER_REGULAR = "seller_regular",
  SELLER_BASIC = "seller_basic",
  HOST = "host",
  WAITER = "waiter",
  NONE = "none",
}

export interface UserMinimum {
  id: string;
  username: string;
  email: string;
  email_verified_at: number | null;
  timezone: string;
  permissions: USER_PERMISSION;
  created_at: number;
  updated_at: number;
}

export interface UserExtended {
  first_name: string | null;
  last_name: string | null;
  gender: USER_GENDER;
  phone: Phone;
  image: Image;
  updated_at: number;
}

export interface UserAddress extends Address {
  id: string;
  created_at: number;
}

export interface UserFull extends UserMinimum {
  extended: UserExtended;
  account: AccountExtended;
  user_address: UserAddress;
}

export interface InitialInfo {
  user: UserFull;
  user_address: UserAddress;
  account: Account;
  token: string;
  kitchen: Kitchen;
}
