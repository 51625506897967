import { AnyAction } from "redux";

import { getUsername } from "../../api/utils";
import { UserFull, USER_ACTION } from "../../types/user.d";

const initialState = [] as UserFull[];

const setUserFull = (_state: typeof initialState, action: AnyAction) => {
  return [{ ...action.payload }];
};

export const updateUserExtended = (
  state: typeof initialState,
  action: AnyAction
) => {
  const userExtended = action.payload;
  const username = getUsername();
  const userFound = state.find((u) => u.username === username);
  const newState = state.filter((u) => u.username !== username);
  if (userFound) {
    newState.push({
      ...userFound,
      extended: userExtended,
    });
  }
  return [...newState];
};

const Reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case USER_ACTION.SET_USER_FULL:
      return setUserFull(state, action);
    case USER_ACTION.UPDATE_USER_EXTENDED:
      return updateUserExtended(state, action);
    default:
      return state;
  }
};

export default Reducer;
