import { MEX_FLAG, US_FLAG } from "../../emojis";

export const esForms = {
  inputs: {
    email: "Correo electrónico",
    personalEmail: "Correo electrónico personal",
    personalPhone: "Teléfono personal",
    password: "Contraseña",
    role: "Rol",
    firstName: "Nombre(s)",
    lastName: "Apellido(s)",
    businessName: "Nombre de tu negocio",
    businessPhone: "Teléfono de tu negocio",
    businessPhoneCode: "Código",
    businessEmail: "Correo electrónico de tu negocio",
    businessDescription:
      "¿Qué te motiva a cocinar? ¿Por qué cocinas? ¿Cuál es tu mejor platillo?",
    dishDescription: "Describe el platillo",
    dishCategory: "Categoría",
    dishCategorySelect: "Selecciona una categoría",
    dishCost: "Precio",
    dishName: "Nombre del platillo",
    dishStatus: "¿Está disponible?",
    dishEnabled: "Activo",
    dishDisabled: "Oculto",
    selectImages: "Selecciona imágenes (MAX: {{max}})",
    imagesSize: "Tamaño recomendado {{size}}",
    address: "Dirección",
    gender: "Género",
    noneGender: "Prefiero no decir",
    femaleGender: "Femenino",
    maleGender: "Masculino",
    accountType: "Tipo de cuenta",
    accountLanguage: "Idioma de la cuenta",
    currency: "Tipo de moneda",
    numberOfTables: "Número de mesas",
    anyInstructions: "¿Instrucciones especiales?"
  },
  placeholders: {
    role: "Solo menús pueden ser registrados",
    businessName: 'Loncheria "Doña Mary"',
    businessEmail: "mi_cocina@hadamenu.com",
    businessDescription:
      'Heredera de una tradición familiar vinculada durante varias generaciones a la buena cocina, la cocina de "Doña Chely" es legado que el talento transformó en vocación, y una leyenda de gastronomía en nuestra localidad. Ser parte de "Hada Menú" es donde puedo hacer lo que amo y puedo pasar tiempo de calidad con la familia.',
    dishName: "Ej. Mole poblano",
    dishDescription: "Pieza de pollo con mole hecho con chile seco, piloncillo, plátano, chocolate. Servido con arroz y frijoles refritos.",
    address: "Ej. Av Rafael Sanzio 150, Zapopan",
  },
  helperText: {
    password:
      "8 caracteres que incluyan al menos una mayúscula, una minúscula, un número y un signo",
    personalEmail:
      "Para cambiar tu correo electrónico, por favor, contactanos en ayuda@hadamenu.com",
    currency:
      "Los clientes verán esto como su moneda de pago al comprar su comida. Importante, la cuenta de destino debe coincidir con esta configuración.",
    accountLanguage:
      "Esta información nos ayuda a entender que tipo de textos mostrarte a ti y tus clientes.",
    maxCharacters: "{{current}}/{{max}} caracteres",
  },
  selectOption: {
    currency: {
      unspecified: "Sin especificar",
      mxn: `${MEX_FLAG} Peso mexicano`,
      usd: `${US_FLAG} Dólar estadounidense`,
    },
    language: {
      spanish: "Español",
      english: "Inglés",
    },
  },
  buttons: {
    enter: "Entrar",
    next: "Siguiente",
    back: "Regresar",
    finishRegistration: "Terminar registro",
    save: "Guardar",
    delete: "Eliminar",
  },
  errors: {
    checkInputs: "Revisa los campos, por favor",
    checkCredentials: "Algo salió mal, revisa credenciales",
    serverError: "Algo salió mal, por favor intenta más tarde",
    userNotCreated: "Intenta después. No pudimos crear tu usuario",
    pickAnAddress: "Elige una dirección",
    noAddressMatches: "Sin coincidencias. Intenta otra dirección",
  },
};
