import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import "./styles/main.css";

import Routes from "./routes/Main";
import theme from "./styles/index";
import Store from "./store/Main";

// CSS compiled
import "./css/main.css";

// import i18n (needs to be bundled ;))
import "./i18n";

const app = (
  // <React.StrictMode>
  <Store>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Routes />
      </ThemeProvider>
    </BrowserRouter>
  </Store>
  // </React.StrictMode>
);

ReactDOM.render(app, document.getElementById("root"));
