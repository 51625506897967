import { Image } from "./image";

export enum DISH_ACTION {
  SET_DISHES = "SET_DISHES",
  SET_DISH = "SET_DISH",
  UPDATE_DISH = "UPDATE_DISH",
  DELETE_DISH = "DELETE_DISH",
  IS_LOADING_DISH = "IS_LOADING_DISH",
}

export interface Dish {
  id: string;
  name: string;
  category_name: string;
  cost: number;
  preparation_in_seconds: number;
  shipping_cost: number;
  description_es: string;
  description_en: string;
  is_available: boolean;
  images: Image[];
  created_at: number;
  updated_at: number;
}
