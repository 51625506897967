import React, { useReducer, createContext } from "react";
import { Account } from "../../types/account";
import { Dish } from "../../types/dish.d";

const AppContext = createContext(null as any);

interface ComponentProps {
  children?: any;
}

export type levelType = "error" | "warning" | "info" | "success";

export type ActionTypes =
  | "OPEN_SEARCH_ADDRESS_MODAL"
  | "CLOSE_SEARCH_ADDRESS_MODAL"
  | "OPEN_LOGIN_MODAL"
  | "CLOSE_LOGIN_MODAL"
  | "OPEN_JOIN_MODAL"
  | "CLOSE_JOIN_MODAL"
  | "OPEN_FORGOT_PASSWORD_MODAL"
  | "CLOSE_FORGOT_PASSWORD_MODAL"
  | "OPEN_RESET_PASSWORD_MODAL"
  | "CLOSE_RESET_PASSWORD_MODAL"
  | "OPEN_PRODUCT_DETAILS_MODAL"
  | "CLOSE_PRODUCT_DETAILS_MODAL"
  | "OPEN_ORDER_DETAILS_MODAL"
  | "CLOSE_ORDER_DETAILS_MODAL"
  | "OPEN_ORDER_REVIEW_MODAL"
  | "CLOSE_ORDER_REVIEW_MODAL"
  | "OPEN_ORDER_HELP_MODAL"
  | "CLOSE_ORDER_HELP_MODAL"
  | "CLOSE_ADD_CARD_MODAL"
  | "OPEN_ADD_CARD_MODAL"
  | "CLOSE_ADD_CLABE_MODAL"
  | "OPEN_ADD_CLABE_MODAL"
  | "CLOSE_ADD_ADDRESS_MODAL"
  | "OPEN_ADD_ADDRESS_MODAL"
  | "CLOSE_MANAGE_PRODUCT_MODAL"
  | "OPEN_MANAGE_PRODUCT_MODAL"
  | "CLOSE_MAP_MODAL"
  | "OPEN_MAP_MODAL"
  | "OPEN_PRODUCT_OTHER_ADDRESSES_MODAL"
  | "CLOSE_PRODUCT_OTHER_ADDRESSES_MODAL"
  | "CLOSE_SEND_MONEY_MODAL"
  | "OPEN_SEND_MONEY_MODAL"
  | "OPEN_QR_CODE_MODAL"
  | "CLOSE_QR_CODE_MODAL"
  | "ALERT_STATE";

export interface initialStateType {
  modalSearchAddress: boolean;
  modalLogin: boolean;
  modalJoin: boolean;
  modalForgotPassword: boolean;
  modalResetPassword: boolean;
  modalProductDetails: boolean;
  modalOrderDetails: boolean;
  modalOrderReview: boolean;
  modalOrderHelp: boolean;
  modalAddCard: boolean;
  modalAddClabe: boolean;
  modalManageProduct: boolean;
  modalProductOtherAddresses: boolean;
  addProduct: boolean;
  updateProduct: boolean;
  modalAddAddress: boolean;
  modalSendMoney: boolean;
  modalQRCode: boolean;
  modalMap: boolean;
  drawerMenu: boolean;
  drawerCart: boolean;
  drawerProfile: boolean;
  dish?: Dish;
  url?: URL;
  minimalAccount?: Pick<Account, "language" | "currency">;
  orderId: number;
  alert: boolean;
  alertLevel: levelType;
  alertMessage: string;
}

const initialState: initialStateType = {
  modalSearchAddress: false,
  modalLogin: false,
  modalJoin: false,
  modalForgotPassword: false,
  modalResetPassword: false,
  modalProductDetails: false,
  modalOrderDetails: false,
  modalOrderReview: false,
  modalOrderHelp: false,
  modalAddCard: false,
  modalAddClabe: false,
  modalManageProduct: false,
  modalProductOtherAddresses: false,
  addProduct: false,
  updateProduct: false,
  modalAddAddress: false,
  modalMap: false,
  modalSendMoney: false,
  modalQRCode: false,
  drawerMenu: false,
  drawerCart: false,
  drawerProfile: false,
  orderId: 0,
  alert: false,
  alertLevel: "success",
  alertMessage: "",
};

const reducer = (
  state: initialStateType,
  action: {
    type: ActionTypes;
    drawerState: boolean;
    dish?: Dish;
    url?: URL;
    orderId: number;
    alertState: boolean;
    alertLevel: levelType;
    alertMessage: string;
    addProduct: boolean;
    updateProduct: boolean;
    minimalAccount: Pick<Account, "language" | "currency">;
  }
) => {
  switch (action.type) {
    case "OPEN_SEARCH_ADDRESS_MODAL":
      return openModalSearchAddress(state);
    case "CLOSE_SEARCH_ADDRESS_MODAL":
      return closeModalSearchAddress(state);
    case "OPEN_LOGIN_MODAL":
      return openModalLogin(state);
    case "CLOSE_LOGIN_MODAL":
      return closeModalLogin(state);
    case "OPEN_FORGOT_PASSWORD_MODAL":
      return openModalForgotPassword(state);
    case "CLOSE_FORGOT_PASSWORD_MODAL":
      return closeModalForgotPassword(state);
    case "OPEN_RESET_PASSWORD_MODAL":
      return openModalResetPassword(state);
    case "CLOSE_RESET_PASSWORD_MODAL":
      return closeModalResetPassword(state);
    case "OPEN_JOIN_MODAL":
      return openModalJoin(state);
    case "CLOSE_JOIN_MODAL":
      return closeModalJoin(state);
    case "OPEN_PRODUCT_DETAILS_MODAL":
      return openModalProductDetails(
        state,
        action.dish,
        action?.minimalAccount
      );
    case "CLOSE_PRODUCT_DETAILS_MODAL":
      return closeModalProductDetails(state);
    case "OPEN_ORDER_DETAILS_MODAL":
      return openModalOrderDetails(state, action.orderId);
    case "CLOSE_ORDER_DETAILS_MODAL":
      return closeModalOrderDetails(state);
    case "OPEN_ORDER_REVIEW_MODAL":
      return openModalOrderReview(state, action.orderId);
    case "CLOSE_ORDER_REVIEW_MODAL":
      return closeModalOrderReview(state);
    case "OPEN_ORDER_HELP_MODAL":
      return openModalOrderHelp(state, action.orderId);
    case "CLOSE_ORDER_HELP_MODAL":
      return closeModalOrderHelp(state);
    case "CLOSE_ADD_CARD_MODAL":
      return closeModalAddCard(state);
    case "OPEN_ADD_CARD_MODAL":
      return openModalAddCard(state);
    case "CLOSE_ADD_CLABE_MODAL":
      return closeModalAddClabe(state);
    case "OPEN_ADD_CLABE_MODAL":
      return openModalAddClabe(state);
    case "CLOSE_ADD_ADDRESS_MODAL":
      return closeModalAddAddress(state);
    case "OPEN_ADD_ADDRESS_MODAL":
      return openModalAddAddress(state);
    case "CLOSE_MAP_MODAL":
      return closeModalMap(state);
    case "OPEN_MAP_MODAL":
      return openModalMap(state);
    case "CLOSE_PRODUCT_OTHER_ADDRESSES_MODAL":
      return closeModalProductOtherAddresses(state);
    case "OPEN_PRODUCT_OTHER_ADDRESSES_MODAL":
      return openModalProductOtherAddresses(state);
    case "CLOSE_SEND_MONEY_MODAL":
      return closeModalSendMoney(state);
    case "OPEN_SEND_MONEY_MODAL":
      return openModalSendMoney(state);
    case "CLOSE_QR_CODE_MODAL":
      return closeModalQRCode(state);
    case "OPEN_QR_CODE_MODAL":
      return openModalQRCode(state);
    case "CLOSE_MANAGE_PRODUCT_MODAL":
      return closeModalManageProduct(state);
    case "OPEN_MANAGE_PRODUCT_MODAL":
      return openModalManageProduct(state, {
        addProduct: action.addProduct,
        updateProduct: action.updateProduct,
        dish: (action as any).dish,
      });
    /*
    case "DRAWER_MENU_STATE":
      return drawerMenuState(state, action.drawerState);
    case "DRAWER_CART_STATE":
      return drawerCartState(state, action.drawerState);
    case "DRAWER_PROFILE_STATE":
      return drawerProfileState(state, action.drawerState);
       */
    case "ALERT_STATE":
      return alertState(
        state,
        action.alertState,
        action.alertLevel,
        action.alertMessage
      );
    default:
      return state;
  }
};

export const AppProvider: React.FC<ComponentProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AppContext.Provider
      value={{ appContextState: state, appContextDispatch: dispatch }}
    >
      {children}
    </AppContext.Provider>
  );
};

const resetState = (state: initialStateType) => {
  return {
    ...state,
    modalSearchAddress: false,
    modalLogin: false,
    modalJoin: false,
    modalForgotPassword: false,
    modalResetPassword: false,
    modalProductDetails: false,
    modalOrderDetails: false,
    modalOrderReview: false,
    modalOrderHelp: false,
    modalAddCard: false,
    modalAddClabe: false,
    modalManageProduct: false,
    modalProductOtherAddresses: false,
    modalAddAddress: false,
    modalMap: false,
    modalSendMoney: false,
    modalQRCode: false,
    drawerMenu: false,
    drawerCart: false,
    drawerProfile: false,
  };
};

const closeModalResetPassword = (state: initialStateType) => {
  return {
    ...state,
    modalResetPassword: false,
  };
};

const openModalResetPassword = (state: initialStateType) => {
  return {
    ...resetState(state),
    modalResetPassword: true,
  };
};

const closeModalForgotPassword = (state: initialStateType) => {
  return {
    ...state,
    modalForgotPassword: false,
  };
};

const openModalForgotPassword = (state: initialStateType) => {
  return {
    ...resetState(state),
    modalForgotPassword: true,
  };
};

const closeModalProductOtherAddresses = (state: initialStateType) => {
  return {
    ...state,
    modalProductOtherAddresses: false,
  };
};

const openModalProductOtherAddresses = (state: initialStateType) => {
  return {
    ...resetState(state),
    modalProductOtherAddresses: true,
  };
};

const closeModalSearchAddress = (state: initialStateType) => {
  return {
    ...state,
    modalSearchAddress: false,
  };
};

const openModalSearchAddress = (state: initialStateType) => {
  return {
    ...resetState(state),
    modalSearchAddress: true,
  };
};

const closeModalLogin = (state: initialStateType) => {
  return {
    ...state,
    modalLogin: false,
  };
};

const openModalLogin = (state: initialStateType) => {
  return {
    ...resetState(state),
    modalLogin: true,
  };
};

const closeModalProductDetails = (state: initialStateType) => {
  const updatedState = { ...state, modalProductDetails: false };
  if (updatedState.hasOwnProperty("dish")) {
    delete updatedState.dish;
  }
  if (updatedState.hasOwnProperty("url")) {
    delete updatedState.url;
  }
  if (updatedState.hasOwnProperty("minimalAccount")) {
    delete updatedState.minimalAccount;
  }
  return updatedState;
};

const openModalProductDetails = (
  state: initialStateType,
  dish?: Dish,
  minimalAccount?: Pick<Account, "language" | "currency">
) => {
  return {
    ...resetState(state),
    modalProductDetails: true,
    dish,
    minimalAccount,
  };
};

const closeModalOrderDetails = (state: initialStateType) => {
  return {
    ...state,
    modalOrderDetails: false,
    orderId: 0,
  };
};

const openModalOrderDetails = (state: initialStateType, orderId: number) => {
  return {
    ...resetState(state),
    modalOrderDetails: true,
    orderId: orderId,
  };
};

const closeModalOrderReview = (state: initialStateType) => {
  return {
    ...state,
    modalOrderReview: false,
    orderId: 0,
  };
};

const openModalOrderReview = (state: initialStateType, orderId: number) => {
  return {
    ...resetState(state),
    modalOrderReview: true,
    orderId: orderId,
  };
};

const closeModalOrderHelp = (state: initialStateType) => {
  return {
    ...state,
    modalOrderHelp: false,
    orderId: 0,
  };
};

const openModalOrderHelp = (state: initialStateType, orderId: number) => {
  return {
    ...resetState(state),
    modalOrderHelp: true,
    orderId: orderId,
  };
};

const closeModalJoin = (state: initialStateType) => {
  return {
    ...state,
    modalJoin: false,
  };
};

const openModalJoin = (state: initialStateType) => {
  return {
    ...resetState(state),
    modalJoin: true,
  };
};

const closeModalAddCard = (state: initialStateType) => {
  return {
    ...state,
    modalAddCard: false,
  };
};

const openModalAddCard = (state: initialStateType) => {
  return {
    ...resetState(state),
    modalAddCard: true,
  };
};

const closeModalSendMoney = (state: initialStateType) => {
  return {
    ...state,
    modalSendMoney: false,
  };
};

const openModalSendMoney = (state: initialStateType) => {
  return {
    ...resetState(state),
    modalSendMoney: true,
  };
};

const closeModalQRCode = (state: initialStateType) => {
  return {
    ...state,
    modalQRCode: false,
  };
};

const openModalQRCode = (state: initialStateType) => {
  return {
    ...resetState(state),
    modalQRCode: true,
  };
};

const closeModalAddClabe = (state: initialStateType) => {
  return {
    ...state,
    modalAddClabe: false,
  };
};

const openModalAddClabe = (state: initialStateType) => {
  return {
    ...resetState(state),
    modalAddClabe: true,
  };
};

const closeModalAddAddress = (state: initialStateType) => {
  return {
    ...state,
    modalAddAddress: false,
  };
};

const openModalAddAddress = (state: initialStateType) => {
  return {
    ...resetState(state),
    modalAddAddress: true,
  };
};

const closeModalMap = (state: initialStateType) => {
  return {
    ...state,
    modalMap: false,
  };
};

const openModalMap = (state: initialStateType) => {
  return {
    ...resetState(state),
    modalMap: true,
  };
};

const closeModalManageProduct = (state: initialStateType) => {
  const newState = {
    ...state,
    modalManageProduct: false,
    updateProduct: false,
    addProduct: false,
  };
  delete (newState as any).dish;
  return newState;
};

const openModalManageProduct = (
  state: initialStateType,
  mode: { addProduct: boolean; updateProduct: boolean; dish: any }
) => {
  return {
    ...resetState(state),
    modalManageProduct: true,
    updateProduct: mode.updateProduct,
    addProduct: mode.addProduct,
    dish: mode.dish,
  };
};

/*
const drawerMenuState = (state: initialStateType, drawer: boolean) => {
  return {
    ...resetState(state),
    drawerMenu: drawer,
  };
};

const drawerCartState = (state: initialStateType, drawer: boolean) => {
  return {
    ...resetState(state),
    drawerCart: drawer,
  };
};

const drawerProfileState = (state: initialStateType, drawer: boolean) => {
  return {
    ...resetState(state),
    drawerProfile: drawer,
  };
};
*/

const alertState = (
  state: initialStateType,
  alert: boolean,
  alertLevel: levelType,
  alertMessage: string
) => {
  return {
    ...state,
    alert: alert,
    alertLevel: alertLevel,
    alertMessage: alertMessage,
  };
};

export default AppContext;
