import React from "react";

const ContainerSideMenu: React.FunctionComponent<{
  children: React.ReactElement;
  open?: boolean;
  onClose?: () => void;
}> = (props) => {
  const open = props.open || false;

  const closeMenu = (delayed: boolean) => {
    let timeout = 0;
    if (delayed) timeout = 300;
    const sideMenu = document.getElementsByClassName("side-menu-card")?.[0];
    sideMenu.classList.remove("side-menu-animation-in");
    sideMenu.classList.add("side-menu-animation-out");
    setTimeout(() => {
      document.documentElement.classList.remove("is-clipped");
      props?.onClose?.();
    }, timeout);
  };

  if (!open) {
    return <></>;
  } else {
    document.documentElement.classList.add("is-clipped");
  }

  return (
    <>
      <div
        className="hero is-fullheight side-menu-background"
        onClick={() => closeMenu(true)}
      />
      <div className="hero is-fullheight side-menu-card side-menu-animation-in">
        <button
          className="button is-hidden-tablet is-absolute is-absolute-to-right is-absolute-no-border"
          onClick={() => closeMenu(false)}
        >
          <span className="icon is-small">
            <i className="fa-solid fa-xmark"></i>
          </span>
        </button>
        {props.children}
      </div>
    </>
  );
};

export default ContainerSideMenu;
