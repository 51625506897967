import React from "react";
import { BrowserRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

import RoutesPublic from "./Public";
//import RoutesBuyer from "./Buyer";
import RoutesSeller from "./Seller";
import Loader from "../components/UI/Loader";
import { getUserToken, getUserType } from "../api/utils";
import {
  useApiPostInitialData,
  useInitialDataSelector,
  useLogoutSelector,
  useSessionSelector,
} from "../store/_page/utils";

const RoutesMain: React.FunctionComponent = () => {
  const { i18n } = useTranslation();
  const { changeLanguage, language, resolvedLanguage } = i18n;
  const getInitialData = useApiPostInitialData();
  const _session = useSessionSelector();
  const userType = getUserType();

  React.useEffect(() => {
    if (!!getUserToken()) getInitialData();
  }, []);

  const preferredLanguage = (
    _session?.account?.language ||
    resolvedLanguage ||
    "es"
  ).toLowerCase();

  React.useEffect(() => {
    if (preferredLanguage !== language) {
      changeLanguage(preferredLanguage);
    }
  }, [preferredLanguage]);

  const isLoggingOut = useLogoutSelector()?.isLoading === true;
  const isLoadingInitialData = useInitialDataSelector()?.isLoading === true;
  if (isLoggingOut || isLoadingInitialData) {
    return <Loader />;
  }

  let Routes;
  switch (_session?.permissions || userType) {
    // case "buyer":
    // Routes = <RoutesBuyer />;
    // break;

    //case "seller":
    //Routes = <RoutesSeller />;
    //break;

    case "seller_admin":
      Routes = <RoutesSeller />;
      break;

    default:
      Routes = <RoutesPublic />;
      break;
  }

  return <BrowserRouter>{Routes}</BrowserRouter>;
};

export default RoutesMain;
