import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ContainerSideMenu from "./Container";
import AppContext from "../../store/context/App";
import { PREVIOUS_ORDERS as PREVIOUS_ORDERS_LINK } from "../../constants/routes";

type SetOpen = (state: ((prevState: boolean) => boolean) | boolean) => void;

const openModal = (setOpen: SetOpen, appContextDispatch: any, name: string) => {
  setOpen(false);
  document.documentElement.classList.remove("is-clipped");
  appContextDispatch({
    type: `OPEN_${name}_MODAL`,
  });
};

const PREVIOUS_ORDERS = "previous_orders";

export const PublicSideMenu: React.FunctionComponent<{
  open: boolean;
  setOpen: SetOpen;
}> = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const { appContextDispatch } = React.useContext(AppContext);
  const commandIds: string[] =
    JSON.parse(localStorage.getItem(PREVIOUS_ORDERS) || "[]") || [];

  return (
    <ContainerSideMenu
      open={open}
      onClose={() => setOpen((prevState) => !prevState)}
    >
      <aside className="menu p-3">
        <p className="menu-label">{t("sideMenu.public.buttons.home")}</p>
        <ul className="menu-list">
          {commandIds.length > 0 ? (
            <li>
              <Link to={PREVIOUS_ORDERS_LINK} onClick={() => setOpen(false)}>
                {t("sideMenu.public.buttons.previousOrders")}
              </Link>
            </li>
          ) : undefined}
          <li>
            <Link
              to={{
                search: "?modal=signup",
              }}
              onClick={() => openModal(setOpen, appContextDispatch, "JOIN")}
            >
              {t("sideMenu.public.buttons.signup")}
            </Link>
          </li>
          <li>
            <Link
              to={{
                search: "?modal=signin",
              }}
              onClick={() => openModal(setOpen, appContextDispatch, "LOGIN")}
            >
              {t("sideMenu.public.buttons.signin")}
            </Link>
          </li>
          <li>
            <Link to={{}} className="active-link">
              {t("sideMenu.version")}: {process.env.REACT_APP_VERSION}
            </Link>
          </li>
        </ul>
      </aside>
    </ContainerSideMenu>
  );
};

export const PublicNavbarButtons: React.FunctionComponent<{
  setOpen: SetOpen;
}> = ({ setOpen }) => {
  const { t } = useTranslation();
  const { appContextDispatch } = React.useContext(AppContext);

  return (
    <>
      <Link
        to={{
          search: "?modal=signup",
        }}
        className="button mr-1 is-primary is-hidden-touch"
        onClick={() => openModal(setOpen, appContextDispatch, "JOIN")}
      >
        {t("sideMenu.public.buttons.signup")}
      </Link>
      <Link
        to={{
          search: "?modal=signin",
        }}
        className="button mr-1 is-primary is-hidden-touch"
        onClick={() => openModal(setOpen, appContextDispatch, "LOGIN")}
      >
        {t("sideMenu.public.buttons.signin")}
      </Link>
      <button
        className="button mr-1 is-primary is-hidden-desktop"
        onClick={() => {
          setOpen((prev) => !prev);
        }}
      >
        <span className="icon">
          <i className="fa-solid fa-bars"></i>
        </span>
      </button>
    </>
  );
};

export const PublicNavbarText: React.FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <div>
      <p className="has-text-weight-semibold">
        {t("sideMenu.public.welcomeMessage")}
      </p>
    </div>
  );
};
