import { AnyAction } from "redux";
import { Newsletter, NEWSLETTER_ACTION } from "../../types/newsletter.d";

const initialState = [] as Newsletter[];

export const setNewsletter = (state: typeof initialState) => {
  return { ...state };
};

const Reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case NEWSLETTER_ACTION:
      return setNewsletter(state);
    default:
      return state;
  }
};

export default Reducer;
