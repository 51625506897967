import React from "react";
import { useHistory } from "react-router-dom";
import { getUrl } from "../utils/url";

const IS_ACTIVE = "is-active";
const MODAL_CLASS = ".modal";
const CLOSE_CLASSES =
  ".modal-background, .modal-close, .modal-card-head .delete, .modal-card-foot .button, .close";

const openModal = (modalId: string) => {
  const $target = document.getElementById(modalId);
  $target?.classList.add(IS_ACTIVE);
  document.documentElement.classList.add("is-clipped");
};

const closeModal = (modalId: string) => {
  const $target = document.getElementById(modalId);
  $target?.classList.remove(IS_ACTIVE);
  document.documentElement.classList.remove("is-clipped");
};

const Modal: React.FunctionComponent<{
  modalId: string;
  open?: boolean;
  queryParam?: string;
  onClose?: () => void;
}> = (props) => {
  const modalId = props.modalId;
  const history = useHistory();

  const open = props.open || false;
  if (open) {
    openModal(modalId);
  } else {
    closeModal(modalId);
  }

  document.addEventListener(
    "click",
    React.useCallback(
      (event) => {
        event.stopPropagation();
        const element = event.target as HTMLElement;
        const $target = element.closest(MODAL_CLASS);
        if (element.matches(CLOSE_CLASSES) && $target) {
          closeModal($target.id);
          props.onClose?.();
          if (props.queryParam) {
            const url = getUrl();
            if (url.searchParams.get("modal") === props.queryParam) {
              url.searchParams.delete("modal");
              history.replace(url.pathname + url.search);
            }
          }
        }
      },
      [history]
    )
  );

  // Add a keyboard event to close all modals
  document.addEventListener("keydown", (event) => {
    const e = event || window.event;
    if (e.keyCode === 27) {
      closeModal(modalId);
    }
  });

  return (
    <div id={modalId} className="modal">
      <div
        className="modal-background"
        onClick={() => closeModal(props.modalId)}
      ></div>
      {props.children}
    </div>
  );
};

export default Modal;
