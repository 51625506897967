export enum ACCOUNT_ACTION {
  SET_ACCOUNT = "SET_ACCOUNT",
  UPDATE_ACCOUNT = "UPDATE_ACCOUNT",
}

export enum ACCOUNT_PROGRAM {
  GIVING_YOU_A_HAND = "giving_you_a_hand",
  GROWING_WITH_YOU = "growing_with_you",
  FIVE_PERCENT = "5_percent",
  FREE = "free",
}

export enum CURRENCY {
  MEX = "MXN",
  USA = "USD",
  UNSPECIFIED = "unspecified",
}

export enum LANGUAGE {
  SPANISH = "ES",
  ENGLISH = "EN",
}

export interface Account {
  id: string;
  kitchen_ids: string[];
  program: ACCOUNT_PROGRAM;
  currency: CURRENCY;
  language: LANGUAGE;
  updated_at: number;
}
