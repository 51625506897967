import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import {
  Facebook,
  Twitter,
  Instagram,
  YouTube,
  Pinterest,
  Announcement,
} from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: "#fff",
      borderRadius: "50%",
      width: "44px",
      height: "44px",
      margin: "8px",
    },
  })
);

interface ComponentProps {
  network: "facebook" | "twitter" | "instagram" | "youtube" | "pinterest";
  onClick?: (...v: any) => any;
}

const SocialButton: React.FC<ComponentProps> = ({ network, onClick }) => {
  const classes = useStyles();

  let Icon;
  switch (network) {
    case "facebook":
      Icon = Facebook;
      break;

    case "twitter":
      Icon = Twitter;
      break;

    case "instagram":
      Icon = Instagram;
      break;

    case "youtube":
      Icon = YouTube;
      break;

    case "pinterest":
      Icon = Pinterest;
      break;

    default:
      Icon = Announcement;
      break;
  }
  return (
    <div className={classes.root} onClick={onClick}>
      <IconButton aria-label={network}>
        <Icon fontSize="medium" color="primary" />
      </IconButton>
    </div>
  );
};

export default SocialButton;
