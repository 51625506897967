import { AnyAction } from "redux";

import { ACCOUNT_ACTION } from "../../types/account.d";
import { Account } from "../../types/account";
import { pushNewElementToState, updateElementInState } from "../utils";

const initialState = [] as Account[];

const setAccount = (state: Account[], action: AnyAction) => {
  return pushNewElementToState(state, action.payload);
};

export const updateAccount = (
  state: typeof initialState,
  action: AnyAction
) => {
  return updateElementInState(state, action.payload);
};

const Reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case ACCOUNT_ACTION.SET_ACCOUNT:
      return setAccount(state, action);
    case ACCOUNT_ACTION.UPDATE_ACCOUNT:
      return updateAccount(state, action);
    default:
      return state;
  }
};

export default Reducer;
