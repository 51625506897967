import { useSelector, useDispatch } from "react-redux";

import { Newsletter } from "../../types/newsletter.d";
import { apiPostNewsletter } from "../../api/newsletter";
import { RootStateType, AppThunk } from "../Main";
import { setNewslettersLoading, PAGE_REDUCERS } from "../_page/actions";

export const useNewsletterSelector = (): Newsletter[] => {
  return useSelector(
    (state: RootStateType) => state.newsletters
  ) as Newsletter[];
};

export const useNewsLetterFormSelector = () => {
  return useSelector(
    (state: RootStateType) => state._page[PAGE_REDUCERS.newsletters]
  );
};

const runApiPostNewsletter =
  (newsletter: Partial<Newsletter>): AppThunk =>
  async (dispatch) => {
    await dispatch(setNewslettersLoading(true));

    try {
      const res = await apiPostNewsletter(newsletter);
      await dispatch(setNewslettersLoading(false));
      if (res.status >= 300 && res.status !== 400) {
        throw new Error(JSON.stringify(res));
      }
      return { data: res.data, status: res.status };
    } catch (err) {
      await dispatch(setNewslettersLoading(false));
      throw err;
    }
  };

export const useApiPostNewsletter = () => {
  const dispatch = useDispatch();
  return (newsletter: Partial<Newsletter>) =>
    dispatch(runApiPostNewsletter(newsletter));
};
