import React, { useContext } from "react";
import { Link } from "react-router-dom";

import SocialButton from "../components/SocialButton";
import AppContext from "../store/context/App";
import SubscribeForm from "../components/forms/Subscribe";
import { getUserType } from "../api/utils";
import { FACEBOOK_URL, INSTAGRAM_URL } from "../constants";
import { useSessionSelector } from "../store/_page/utils";
import { useTranslation } from "react-i18next";
import { scrollTop } from "../utils/scroll";
import {
  ABOUT,
  DASHBOARD_KITCHENS_DISHES,
  DASHBOARD_PROFILES,
  FAQ,
  PRICE,
  PRIVACY_POLICY,
  TERMS_OF_SERVICE,
} from "../constants/routes";

interface ComponentProps {}

const Footer: React.FC<ComponentProps> = () => {
  const dt = new Date();
  const { appContextDispatch } = useContext(AppContext);
  const { t } = useTranslation();

  const user = useSessionSelector();
  const userType = user?.permissions || getUserType();

  return (
    <footer className="footer has-background-white-ter">
      <div className="columns is-gapless py-3">
        {!userType && (
          <div className="column is-two-thirds-tablet is-half-desktop has-text-centered content my-auto mx-3">
            <h3>{t("footer.sections.subscribe.title")}</h3>
            <SubscribeForm
              submitted={() => {
                appContextDispatch({
                  type: "ALERT_STATE",
                  alertState: true,
                  alertLevel: "success",
                  alertMessage:
                    "Te estaremos enviando promociones y noticias 😘",
                });
              }}
            />
          </div>
        )}
        <div className="column"></div>
        <div className="column is-one-quarter is-flex is-justify-content-center">
          <SocialButton
            network="facebook"
            onClick={() => {
              window.open(FACEBOOK_URL);
            }}
          />
          {/*<SocialButton network="twitter" />*/}
          <SocialButton
            network="instagram"
            onClick={() => {
              window.open(INSTAGRAM_URL);
            }}
          />
          {/*<SocialButton network="youtube" />*/}
          {/*<SocialButton network="pinterest" />*/}
          {/*<div className={classes.containerDownload}>
            <div className={classes.downloadAndroidApp}></div>
            <div className={classes.downloadiOSApp}></div>
          </div>*/}
        </div>
      </div>
      <hr className="separator" />
      <div className="columns is-gapless is-centered mt-3 py-3">
        <div className="column is-one-quarter content my-0 has-text-centered">
          <div className="is-flex is-flex-direction-column">
            <h3>{t("footer.sections.links.company.title")}</h3>
            <Link to={ABOUT} onClick={scrollTop} className="button is-ghost">
              {t("footer.sections.links.company.whoAreWe")}
            </Link>
            <Link to={PRICE} onClick={scrollTop} className="button is-ghost">
              {t("footer.sections.links.company.pricing")}
            </Link>
            <Link to={FAQ} onClick={scrollTop} className="button is-ghost">
              {t("footer.sections.links.company.faq")}
            </Link>
          </div>
        </div>
        <div className="column is-one-quarter content my-0 has-text-centered">
          <div className="is-flex is-flex-direction-column">
            <h3>{t("footer.sections.links.profile.title")}</h3>
            {JSON.stringify(user) === "{}" ? (
              <>
                <Link
                  to={{
                    search: "?modal=signin",
                  }}
                  onClick={() => {
                    scrollTop();
                    appContextDispatch({
                      type: "OPEN_LOGIN_MODAL",
                    });
                  }}
                  className="button is-ghost"
                >
                  {t("sideMenu.public.buttons.signin")}
                </Link>
                <Link
                  to={{
                    search: "?modal=signup",
                  }}
                  onClick={() => {
                    scrollTop();
                    appContextDispatch({
                      type: "OPEN_JOIN_MODAL",
                    });
                  }}
                  className="button is-ghost"
                >
                  {t("sideMenu.public.buttons.signup")}
                </Link>
              </>
            ) : (
              <>
                <Link
                  to={
                    userType === "seller_admin"
                      ? DASHBOARD_KITCHENS_DISHES
                      : DASHBOARD_PROFILES
                  }
                  className="button is-ghost"
                  onClick={scrollTop}
                >
                  {userType === "seller_admin"
                    ? t("footer.sections.links.profile.myProducts")
                    : t("footer.sections.links.profile.myProfile")}
                </Link>
              </>
            )}
          </div>
        </div>
        <div className="column"></div>
      </div>
      {/* <Grid item xs={12} md={4} className={classes.section}>
                    <Typography className={classes.stayUpdate} variant="body1">
                        Prensa
                    </Typography>
                    <div className={classes.sectionLinkContainer}>
                        <Link to="/news" className={classes.sectionLink}>
                            Noticias
                        </Link>
                    </div>
                </Grid> */}
      <hr className="separator" />
      <div className="columns is-gapless my-3">
        <div className="column has-text-centered">
          <Link
            to={TERMS_OF_SERVICE}
            onClick={scrollTop}
            className="button is-ghost"
          >
            {t("footer.sections.links.termsAndPolicies.terms")}
          </Link>
        </div>
        <div className="column has-text-centered">
          <Link
            to={PRIVACY_POLICY}
            onClick={scrollTop}
            className="button is-ghost"
          >
            {t("footer.sections.links.termsAndPolicies.privacy")}
          </Link>
        </div>
      </div>
      <hr className="separator" />
      <div className="columns is-gapless mt-3 pb-2">
        <div className="column has-text-centered content">
          <p>
            {t("footer.sections.copyRight")} &copy; Hada Menú 2019 -{" "}
            {dt.getFullYear()}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
