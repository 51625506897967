import React from "react";

const InputField: React.FunctionComponent<
  React.InputHTMLAttributes<HTMLInputElement> & Record<string, any>
> = (props) => {
  const {
    label,
    isRequired,
    leftIcon,
    errorText,
    helpText,
    disabled,
    onClickLeftIcon,
    ...rest
  } = props;
  return (
    <div className={`field${helpText ? " is-explained" : ""}`}>
      <fieldset disabled={disabled}>
        {!!label && (
          <label className="label">
            {label}{" "}
            {isRequired ? <span className="has-text-danger">*</span> : null}
          </label>
        )}
        <p className={`control${leftIcon ? " has-icons-left" : ""}`}>
          <input
            className={`input is-medium${errorText ? " is-danger" : ""}`}
            {...rest}
          />
          {leftIcon ? (
            <span
              onClick={onClickLeftIcon}
              className={`icon is-small is-left${
                onClickLeftIcon ? " is-clickable" : ""
              }`}
            >
              <i className={leftIcon.toString()}></i>
            </span>
          ) : null}
        </p>
        {errorText || helpText ? (
          <p className={`help has-text-left${errorText ? " is-danger" : ""}`}>
            {errorText ? errorText : helpText}
          </p>
        ) : null}
      </fieldset>
    </div>
  );
};

export default InputField;
