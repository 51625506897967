export const enViewSeller = {
  informative:
    "This is 100% personal information that will not be shared with your customers.",
  account: {
    program: {
      free: "free",
      giving_you_a_hand: "giving you a hand",
      growing_with_you: "growing with you",
    },
  },
};
