import { Kitchen, KITCHEN_ACTION } from "../../types/kitchen.d";

export const setKitchen = (kitchen: Kitchen) => {
  return {
    type: KITCHEN_ACTION.SET_KITCHEN,
    payload: kitchen,
  };
};

export const updateKitchen = (updatedKitchen: Kitchen) => {
  return {
    type: KITCHEN_ACTION.UPDATE_KITCHEN,
    payload: updatedKitchen,
  };
};
