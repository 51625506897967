import { sideMenu } from "./sideMenu";

export const modals = {
  registerInvite: {
    bodyText: `<p>
        ¿Quieres empezar tu propio negocio vendiendo comida? ¿O ya
        tienes un negocio pequeño? Tal vez ya tienes un gran
        restaurante, pero aún no has <strong>digitalizado tu menú</strong> 🥲.
      </p>
      <p>
        No importa cuál sea el tamaño de tu negocio 🎉, <strong>"Hada Menú"</strong>
        es la solución para compartir y vender comida en línea 🍔.
      </p>`,
    footerButtons: {
      register: `¡${sideMenu.public.buttons.signup} ahora!`,
      close: "Ya me registré",
    },
  },
  login: {
    title: sideMenu.public.buttons.signin,
    form: {},
    footer: "¡No esperes más!",
    footerButton: "Registrate aquí",
  },
  join: {
    title: {
      stepOne: sideMenu.public.buttons.signup,
      stepTwo: "Cuentanos un poco más...",
      addressStep: "¿Dónde está ubicado tu local?",
      submitStep: "Guardando...",
    },
    welcome: "Bienvenido{{userName}}",
    footer: "🎉 ¿Ya tienes una cuenta?",
    footerButton: "Iniciar sesión aquí",
  },
  addDish: {
    title: "Nuevo Platillo",
    close: "Cerrar"
  },
};
