import axios from "./axios";

export const isError = (err: unknown): err is Error => err instanceof Error;

export const apiFetch = <T>(source: string) => {
  if (!source) {
    throw new Error("Provide a source you want to fetch");
  }

  return {
    create: async (data: T, headers?: Record<string, any>) => {
      return await axios({
        url: source,
        method: "POST",
        headers,
        data,
      });
    },
    read: async (headers?: Record<string, any>) => {
      return await axios({
        url: source,
        method: "GET",
        headers,
      });
    },
    update: async (data: T, headers?: Record<string, any>) => {
      return await axios({
        url: source,
        method: "PUT",
        headers,
        data,
      });
    },
    delete: async (headers?: Record<string, any>) => {
      return await axios({
        url: source,
        method: "DELETE",
        headers,
      });
    },
  };
};
