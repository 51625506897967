import { AxiosResponse } from "axios";
import { apiFetch } from "../utils/api";
import { getUserToken } from "./utils";
import { Account } from "../types/account";

export const apiPutAccount = async (
  data: Partial<Account>
): Promise<AxiosResponse<Account>> => {
  const source = apiFetch("/accounts");
  try {
    const authToken = getUserToken();
    const response = await source.update(data, {
      Authorization: authToken,
    });
    return response;
  } catch (err: any) {
    return err.response;
  }
};

export const apiGetMinimalAccount = async (
  kitchenId: string
): Promise<AxiosResponse<Pick<Account, "language" | "currency">>> => {
  const source = apiFetch(`/accounts/kitchen/${kitchenId}`);
  try {
    const response = await source.read();
    return response;
  } catch (err: any) {
    return err.response;
  }
};
