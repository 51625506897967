import { Image } from "./image";

export enum CATEGORY_ACTION {
  SET_CATEGORIES = "SET_CATEGORIES",
  SET_CATEGORY = "SET_CATEGORY",
}

export interface Category {
  id: string;
  name: string;
  image: Image;
  created_at: number;
  updated_at: number;
}
