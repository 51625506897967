import { useSelector, useDispatch } from "react-redux";

import { Account } from "../../types/account";
import { AppThunk, RootStateType } from "../Main";
import { setAccount, updateAccount } from "./actions";
import { PAGE_REDUCERS, setAccountsLoading } from "../_page/actions";
import { apiGetMinimalAccount, apiPutAccount } from "../../api/accounts";
import { IPaginationResponse } from "../../api/utils";

export const useAccountsSelector = (): Account[] => {
  return useSelector((state: RootStateType) => state.accounts);
};

export const useFirstAccountSelector = (): Account | undefined => {
  const listOfAccounts = useAccountsSelector();
  return listOfAccounts?.[0];
};

export const useSetAccountDispatch = (account: Account) => {
  const dispatch = useDispatch();
  dispatch(setAccount(account));
};

export const runApiPutAccount =
  (partialAccount: Partial<Account>): AppThunk =>
  async (dispatch) => {
    await dispatch(setAccountsLoading(true));
    try {
      const res = await apiPutAccount(partialAccount);
      await dispatch(setAccountsLoading(false));
      if (res.status >= 300) {
        throw new Error(JSON.stringify(res));
      }
      await dispatch(updateAccount({ ...res.data }));
      return res.data;
    } catch (err) {
      await dispatch(setAccountsLoading(false));
      throw err;
    }
  };

export const useApiPutAccount = () => {
  const dispatch = useDispatch();
  return (partialAccount: Partial<Account>) =>
    dispatch(runApiPutAccount(partialAccount));
};

export const useAccountsPageSelector = (): IPaginationResponse & {
  isLoading: boolean;
} => {
  return useSelector(
    (state: RootStateType) => state._page[PAGE_REDUCERS.accounts]
  );
};

export const runApiGetMinimalAccount =
  (kitchenId: string): AppThunk =>
  async (dispatch) => {
    await dispatch(setAccountsLoading(true));
    try {
      const res = await apiGetMinimalAccount(kitchenId);
      await dispatch(setAccountsLoading(false));
      if (res.status >= 300) {
        throw new Error(JSON.stringify(res));
      }
      return res.data;
    } catch (err) {
      await dispatch(setAccountsLoading(false));
      throw err;
    }
  };

export const useApiGetMinimalAccount = () => {
  const dispatch = useDispatch();
  return (kitchenId: string) => dispatch(runApiGetMinimalAccount(kitchenId));
};
